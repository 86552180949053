<template>
  <div class="d-flex align-items-end">
    <UyduButton :changeSelectedScreen="changeSelectedScreen" />
    <KatmalarButton :katmanlar="!katmanlar" :openKatmalar="openKatmalar" />
    <KatmanlarMenu
      :katmanlar="katmanlar"
      :bsKatmanlar="bsKatmanlar"
      :krtyKatmanlar="krtyKatmanlar"
      :userKatmanlar="userKatmanlar"
      :openBsKatmalar="openBsKatmalar"
      :openKrtyKatmanlar="openKrtyKatmanlar"
      :openUserKatmanlar="openUserKatmanlar"
      :getMahaleler="getMahaleler"
    />
    <Transition name="slide-fade">
      <div
        class="d-flex flex-row align-items-center justify-content-between"
        v-if="!katmanlar"
      >
        <button
          type="button"
          class="vector-altliklar d-flex flex-column align-items-center justify-content-between btn btn-light"
        >
          <VectorAltliklariSvg
            height="1.58rem"
            style="margin-top: 0.9rem"
            class="mb-2"
          />
          Vektör Katmanlar
        </button>
        <!-- <button
          type="button"
          class="ortofotolar d-flex flex-column align-items-center justify-content-between btn btn-light"
        >
          <OrtoFotolarSvg height="1.58rem" />
          Ortofotolar
        </button> -->
        <button
          v-if="this.$store.state.auth.logged"
          @click="toggleVehicles"
          type="button"
          class="car-with-gps d-flex flex-column align-items-center justify-content-between btn btn-light"
          :style="{
            backgroundColor: this.$store.state.arvento.show
              ? '#d22a24'
              : '#fff',
            color: !this.$store.state.arvento.show ? '#000' : '#fff',
          }"
        >
          <CarWithGpsSvg
            :color="this.$store.state.arvento.show ? '#FFFFFF' : '#3c4043'"
            height="1.58rem"
          />
          Araç Takip
        </button>
        <button
          v-if="this.$store.state.auth.logged"
          type="button"
          class="yatirimlar d-flex flex-column align-items-center justify-content-between btn btn-light"
          @click="openAndCloseYatirimlar"
          :style="{
            backgroundColor: showCluster ? '#205c42' : '#fff',
            color: !showCluster ? '#000' : '#fff',
          }"
        >
          <YatirimlarWhiteSvg height="1.58rem" v-if="showCluster" />
          <YatirimlarSvg height="1.58rem" v-else />
          Yatırımlar
        </button>
      </div>
    </Transition>
  </div>
</template>

<script>
import UyduButton from './UyduButton.vue'
import KatmalarButton from './KatmalarButton.vue'
import KatmanlarMenu from './KatmanlarMenu.vue'
import VectorAltliklariSvg from '../../../assets/icons/VectorAltliklariSvg.vue'
// import OrtoFotolarSvg from '../../../assets/icons/OrtoFotolarMapSvg.vue'
import CarWithGpsSvg from '../../../assets/icons/CarWithGpsSvg.vue'
import YatirimlarSvg from '../../../assets/icons/YatirimlarSvg.vue'
import YatirimlarWhiteSvg from '../../../assets/icons/YatirimlarWhiteSvg.vue'

export default {
  data() {
    return {
      katmanlar: false,
      bsKatmanlar: false,
      krtyKatmanlar: false,
      userKatmanlar: false,
      interval: null,
    }
  },
  components: {
    UyduButton,
    KatmalarButton,
    KatmanlarMenu,
    VectorAltliklariSvg,
    // OrtoFotolarSvg,
    CarWithGpsSvg,
    YatirimlarSvg,
    YatirimlarWhiteSvg,
  },
  props: {
    showCluster: Boolean,
    showVectorCoasters: Boolean,
    showClusterFunction: Function,
    showProjectFunction: Function,
    changeSelectedScreen: Function,
    showProfileFunction: Function,
    getMahaleler: Function,
    showVectorCoastersFunction: Function,
  },
  methods: {
    openAndCloseYatirimlar() {
      this.showClusterFunction()
      this.showProjectFunction(false)
    },
    openKatmalar() {
      this.katmanlar = !this.katmanlar
    },
    openBsKatmalar() {
      this.bsKatmanlar = !this.bsKatmanlar
      this.krtyKatmanlar = false
      this.userKatmanlar = false
    },
    openKrtyKatmanlar() {
      this.krtyKatmanlar = !this.krtyKatmanlar
      this.bsKatmanlar = false
      this.userKatmanlar = false
    },
    openUserKatmanlar() {
      if (this.showVectorCoasters) {
        this.showVectorCoastersFunction(false)
        this.showProfileFunction(false)
      } else {
        this.showVectorCoastersFunction(true)
      }
    },
    intervalFunction(bool) {
      const changes = (Math.random() * 100).toFixed(2) + '%'
      if (bool) {
        this.interval = setInterval(() => {
          this.$store.dispatch('setArventoData')
        }, 5000)
      } else {
        clearInterval(this.interval)
      }
    },
    toggleVehicles() {
      this.$store.dispatch('setArventoToggle', !this.$store.state.arvento.show)
      this.intervalFunction(this.$store.state.arvento.show)
    },
  },
}
</script>

<style scoped>
button {
  margin-left: 0.85rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.vector-altliklar {
  padding-top: 0.5rem;
  padding-bottom: 0.7rem;
  border-bottom-width: 0.3rem;
  border-bottom-color: #2da125;
}

.vector-altliklar:hover {
  border-bottom-color: #2da125;
}

.ortofotolar {
  padding-top: 0.5rem;
  padding-bottom: 0.7rem;
  border-bottom-width: 0.3rem;
  border-bottom-color: #12a5f0;
}

.ortofotolar:hover {
  border-bottom-color: #12a5f0;
}

.car-with-gps {
  padding-top: 0.5rem;
  padding-bottom: 0.7rem;
  border-bottom-width: 0.3rem;
  border-bottom-color: #d22a24;
}

.car-with-gps-active {
  background: #d22a24;
  color: #ffffff;
}

.car-with-gps:hover {
  border-bottom-color: #d22a24;
}

.yatirimlar {
  padding-top: 0.5rem;
  padding-bottom: 0.7rem;
  border-bottom-width: 0.3rem;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-color: #205c42;
}

.yatirimlar:hover {
  border-bottom-color: #205c42;
}

.yatirimlar:active {
  border-color: #205c42 !important;
}

.vector-altliklar svg {
  height: 1.37rem;
}

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.5s;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-850px);
  opacity: 0;
}
@media only screen and (min-width: 3800px) {
  button {
    margin-left: 0.85rem;
  }

  .vector-altliklar {
    padding: 1rem 2rem;
  }

  .ortofotolar {
    padding: 1rem 2rem;
  }

  .car-with-gps {
    padding: 1rem 2rem;
  }

  .yatirimlar {
    padding: 1rem 2rem;
  }
  svg {
    height: 3rem;
  }
  .vector-altliklar svg {
    height: 2.7rem;
  }
}

@media only screen and (max-width: 1500px) {
  button {
    margin-left: 0.5rem;
    font-size: 14px;
  }
  button svg {
    height: 1.38rem;
  }

  .vector-altliklar {
    padding-top: 0.5rem;
    padding-bottom: 0.75rem;
    border-bottom-width: 0.3rem;
    border-bottom-color: #2da125;
  }

  .vector-altliklar:hover {
    border-bottom-color: #2da125;
  }

  .ortofotolar {
    padding-top: 0.7rem;
    padding-bottom: 0.75rem;
    border-bottom-width: 0.3rem;
    border-bottom-color: #12a5f0;
  }

  .ortofotolar:hover {
    border-bottom-color: #12a5f0;
  }

  .car-with-gps {
    padding-top: 0.7rem;
    padding-bottom: 0.75rem;
    border-bottom-width: 0.3rem;
    border-bottom-color: #d22a24;
  }

  .car-with-gps:hover {
    border-bottom-color: #d22a24;
  }

  .yatirimlar {
    padding-top: 0.7rem;
    padding-bottom: 0.75rem;
    border-bottom-width: 0.3rem;
    border-bottom-color: #205c42;
  }

  .yatirimlar:hover {
    border-bottom-color: #205c42;
  }
}

@media only screen and (max-width: 1100px) {
  button {
    margin-left: 0.5rem;
    font-size: 11px;
  }
  button svg {
    height: 1.58rem;
  }

  .vector-altliklar {
    padding-top: 0;
    padding-bottom: 0.3rem;
    border-bottom-width: 0.3rem;
    border-bottom-color: #2da125;
  }

  .vector-altliklar:hover {
    border-bottom-color: #2da125;
  }

  .ortofotolar {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    border-bottom-width: 0.3rem;
    border-bottom-color: #12a5f0;
  }

  .ortofotolar:hover {
    border-bottom-color: #12a5f0;
  }

  .car-with-gps {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    border-bottom-width: 0.3rem;
    border-bottom-color: #d22a24;
  }

  .car-with-gps:hover {
    border-bottom-color: #d22a24;
  }

  .yatirimlar {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    border-bottom-width: 0.3rem;
    border-bottom-color: #205c42;
  }

  .yatirimlar:hover {
    border-bottom-color: #205c42;
  }
}

@media only screen and (max-width: 770px) {
  button {
    margin-left: 0.5rem;
    font-size: 10px;
  }
  button svg,
  .vector-altliklar svg {
    height: 1.2rem;
  }

  .vector-altliklar {
    padding-top: 0;
    padding-bottom: 0.2rem;
    border-bottom-width: 0.2rem;
    border-bottom-color: #2da125;
  }

  .vector-altliklar:hover {
    border-bottom-color: #2da125;
  }

  .ortofotolar {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    border-bottom-width: 0.2rem;
    border-bottom-color: #12a5f0;
  }

  .ortofotolar:hover {
    border-bottom-color: #12a5f0;
  }

  .car-with-gps {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    border-bottom-width: 0.2rem;
    border-bottom-color: #d22a24;
  }

  .car-with-gps:hover {
    border-bottom-color: #d22a24;
  }

  .yatirimlar {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    border-bottom-width: 0.2rem;
    border-bottom-color: #205c42;
  }

  .yatirimlar:hover {
    border-bottom-color: #205c42;
  }
}
</style>
