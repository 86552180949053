<template>
  <div class="badge item2">
    <EditSvg width="0.65rem" />
  </div>
</template>

<script>
import EditSvg from "@/assets/icons/EditSvg.vue";

export default {
  props: {},

  components: {
    EditSvg,
  },
};
</script>

<style scoped>
.badge {
  background-color: red;
  display: inline-block;
  margin-left: 0.5rem;
  border-radius: 100%;
}

.item2 {
  background-color: #ff8800;
  padding: 0.4rem 0.45rem 0.45rem 0.5rem;
}

@media screen and (min-width: 3800px) {
  .item2 {
    padding: 0.6rem 0.65rem 0.65rem 0.7rem;
  }

  .item2 svg {
    width: 1.5rem;
  }
}
@media screen and (max-width: 770px) {
  
  .item2 {
    padding: 0.35rem 0.35rem 0.32rem 0.4rem;
  }
  .item2 svg {
    width: 0.45rem;
  }
}
</style>