<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    viewBox="0 0 33.793 32.934"
  >
    <path
      id="building-user-svgrepo-com"
      d="M8.156,9.874H9.874M8.156,15.03H9.874m6.874,0h1.719m-1.719,5.156h1.719m-10.311,0H9.874M16.748,9.874h1.719M9.874,33.934V28.778a3.437,3.437,0,0,1,6.874,0v5.156Zm0,0H3V5.75a4.27,4.27,0,0,1,.187-1.811,1.719,1.719,0,0,1,.751-.751A4.27,4.27,0,0,1,5.75,3H20.873a4.269,4.269,0,0,1,1.811.187,1.718,1.718,0,0,1,.751.751,4.269,4.269,0,0,1,.187,1.811v7.562M31.7,21.045a2.578,2.578,0,1,1-2.578-2.578A2.578,2.578,0,0,1,31.7,21.045Zm3.093,12.889v-.859a4.3,4.3,0,0,0-4.3-4.3H27.919a4.3,4.3,0,0,0-4.3,4.3v.859Z"
      transform="translate(-2 -2)"
      fill="none"
      :stroke="selectedFilter === 'Ruhsat' ? '#fff' : '#f60'"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
    selectedFilter: String,
  },
};
</script>