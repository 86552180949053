<template>
  <svg
    id="map-svgrepo-com_1_"
    data-name="map-svgrepo-com (1)"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    viewBox="0 0 46 46"
  >
    <path
      id="secondary"
      d="M2,18.1V6.6A4.6,4.6,0,0,1,6.6,2H21.274l9.2,16.1Zm18.4,4.6V48h23A4.6,4.6,0,0,0,48,43.4V22.7Z"
      transform="translate(-2 -2)"
      fill="#808181"
    />
    <path
      id="primary"
      d="M26.564,2H43.4A4.6,4.6,0,0,1,48,6.6V18.1H35.764ZM15.8,22.7H2V43.4A4.6,4.6,0,0,0,6.6,48h9.2Z"
      transform="translate(-2 -2)"
      fill="#3c4043"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
  },
};
</script>