<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    height="49.071"
    viewBox="0 0 41.711 49.071"
  >
    <path
      id="share-svgrepo-com_1_"
      data-name="share-svgrepo-com (1)"
      d="M28.779,10.179a8.2,8.2,0,1,1,2.365,5.742L19.771,23.664a8.179,8.179,0,0,1-.447,4.7L31.8,36.56a8.171,8.171,0,1,1-3.016,6.332,8.122,8.122,0,0,1,.708-3.326L17.117,31.44a8.176,8.176,0,1,1,1.034-11.246l11.023-7.5A8.139,8.139,0,0,1,28.779,10.179Z"
      transform="translate(-3.5 -2)"
      :fill="fill"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
    fill: String,
  },
};
</script>