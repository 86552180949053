<template>
  <button
    @click="openKatmalar"
    type="button"
    :class="
      !katmanlar
        ? 'katmanlarOpen d-flex flex-column align-items-center justify-content-between btn btn-light'
        : 'katmanlar d-flex flex-column align-items-center justify-content-between btn btn-light'
    "
  >
    <KatmanlarSvg height="1.58rem" />
    Katmanlar
  </button>
</template>

<script>
import KatmanlarSvg from "../../../assets/icons/KatmanlarSvg.vue";

export default {
  props: {
    katmanlar: Boolean,
    openKatmalar: Function,
  },
  components: {
    KatmanlarSvg,
  },
};
</script>

<style scoped>
.katmanlar {
  padding-top: 0.5rem;
  padding-bottom: 0.7rem;
  border-bottom-width: 0.3rem;
  border-bottom-color: #ff8800;
  margin-left: 1rem;
}

.katmanlarOpen {
  padding-top: 0.5rem;
  padding-bottom: 0.7rem;
  border-bottom-width: 0.3rem;
  border-top-width: 0.15rem;
  border-left-width: 0.15rem;
  border-right-width: 0.15rem;
  border-bottom-color: #ff8800;
  border-top-color: #ff8800;
  border-left-color: #ff8800;
  border-right-color: #ff8800;

  margin-left: 1rem;
}

.katmanlar:hover {
  border-bottom-color: #ff8800;
}

.katmanlar svg {
  height: 1.37rem;
}

.katmanlarOpen:hover {
  border-bottom-color: #ff8800;
  border-top-color: #ff8800;
  border-left-color: #ff8800;
  border-right-color: #ff8800;
}

.katmanlarOpen svg {
  height: 1.39rem;
}

@media only screen and (min-width: 3800px) {
  .katmanlar {
    padding: 1rem 2rem;
  }
  .katmanlarOpen {
    padding: 1rem 2rem;
  }
  .katmanlar svg {
    height: 2.7rem;
  }
  .katmanlarOpen svg {
    height: 2.7rem;
  }
}

@media only screen and (max-width: 1100px) {
  .katmanlar {
    padding-top: 0;
    padding-bottom: 0.3rem;
    border-bottom-width: 0.3rem;
    border-bottom-color: #ff8800;
  }

  .katmanlar:hover {
    border-bottom-color: #ff8800;
  }
  .katmanlarOpen {
    padding-top: 0;
    padding-bottom: 0.3rem;
    border-bottom-width: 0.3rem;
    border-bottom-color: #ff8800;
  }

  .katmanlarOpen:hover {
    border-bottom-color: #ff8800;
  }
}

@media only screen and (max-width: 770px) {
  .katmanlar {
    padding-bottom: 0.1rem;
    border-bottom-width: 0.3rem;
    margin-left: .5rem;
  }
  .katmanlar svg {
    height: 1.25rem;
  }

  .katmanlarOpen {
    padding-bottom: 0.1rem;
    border-bottom-width: 0.3rem;
  }
  .katmanlarOpen svg {
    height: 1.25rem;
  }

}
</style>