const getModule = {
  state: {
    url: [],
  },
  mutations: {
    // const handler = (kat, kamera) => {
    //   return proxy({
    //     url: `rtsp://admin:admin@10.50.${kat}.${kamera}//Master-0`,
    //     verbose: false,
    //   })
    // }

    setCamerasData(state, data) {
      state.data = data
    },
  },
  actions: {
    setCamerasData({ state }) {
      console.log("state: ", state)
    },
  },
  getters: {
    allCameras: (state) => state.data,
  },
}

export default getModule
