<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    viewBox="0 0 31.302 31.656"
  >
    <path
      id="dig-dug-svgrepo-com"
      d="M28.649,17.8l-6.336,6.429a8.715,8.715,0,0,0,4.42,2.684,4.46,4.46,0,0,0,2.891-.314c1.956,1.929,3.886,3.892,5.808,5.868l1.482-1.489-5.848-5.842a4.063,4.063,0,0,0,.3-2.857,9.108,9.108,0,0,0-2.717-4.48ZM37,18.434a9.894,9.894,0,0,0-1.836.18A55.186,55.186,0,0,1,53.432,36.888a17.718,17.718,0,0,0-3.959-11.33,3.177,3.177,0,0,1-3.912-3.912A14.36,14.36,0,0,0,37,18.434Zm-7.618,2.2a5.981,5.981,0,0,1,.754,1.889,2.77,2.77,0,0,1-.574,2.6,2.787,2.787,0,0,1-2.59.574,6.076,6.076,0,0,1-1.9-.754Zm17.592,1.062a1.562,1.562,0,0,0,.287,2.163,1.576,1.576,0,0,0,2.17.287,1.556,1.556,0,0,0-.287-2.17A1.594,1.594,0,0,0,46.976,21.692Zm-3.759,4.754L22.3,47.369a2.455,2.455,0,0,0,1.636,1.662l20.93-20.937a16.715,16.715,0,0,0-1.649-1.649ZM40.333,34.4,38.791,35.94c2.17,2.27,4.34,4.56,6.5,6.843a19.606,19.606,0,0,1-3.1,2.41c3.732,3.739,8.339,5.214,10.816,3.639,1.522-2.49.073-7.11-3.639-10.822A24.213,24.213,0,0,1,47,41.06Z"
      transform="translate(-22.3 -17.8)"
      :fill="selectedFilter === 'Yatırımlar' ? '#fff' : '#205c42'"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
    selectedFilter: String,
  },
};
</script>