<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :height="height"
    viewBox="0 0 38.849 38.849"
  >
    <g id="search-alt-svgrepo-com" transform="translate(-1 -1)">
      <path
        id="Path_83"
        data-name="Path 83"
        d="M17,17l7.56,7.56"
        transform="translate(12.46 12.46)"
        fill="none"
        stroke="#3c4043"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="4"
      />
      <path
        id="Path_84"
        data-name="Path 84"
        d="M33.24,18.12A15.12,15.12,0,1,1,18.12,3,15.12,15.12,0,0,1,33.24,18.12Z"
        fill="none"
        stroke="#3c4043"
        stroke-width="4"
      />
    </g>
  </svg>
</template>


<script>
export default {
  props: {
    width: String,
    height: String,
  },
};
</script>