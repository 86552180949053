<template>
  <div class="menuContainer">
    <div class="menuText">Menü</div>
  </div>
  <div class="menuButtons">
    <UserMenuButton
      text="Kullanıcı Profiliniz"
      @click="showProfileFunction(true)"
      ><UserProfileButtonSvg width="1.2rem"
    /></UserMenuButton>
    <!-- <UserMenuButton text="Faliyetleriniz" :count="9"
      ><YourWorksSvg width="1.2rem"
    /></UserMenuButton> -->
    <!-- <UserMenuButton text="Katmanlarınız" :count="5"
      ><KatmanlarButtonSvg width="1.2rem"
    /></UserMenuButton> -->
    <UserMenuButton
      text="Vektör Katmanlarınız"
      :count="this?.$store?.state?.layers?.vectors?.length ?? 0"
      @click="showVectorCoastersFunction(true)"
    >
      <VectorAltliklariButtonSvg width="1.2rem" />
    </UserMenuButton>
    <!-- <UserMenuButton text="Envanter"
      ><EnvanterSvg width="1.2rem"
    /></UserMenuButton> -->
    <UserMenuButton text="Geçmiş İşlemleriniz"
      ><HistorySvg width="1.2rem"
    /></UserMenuButton>
  </div>
</template>

<script>
import UserMenuButton from './UserMenuButton.vue'
import UserProfileButtonSvg from '@/assets/icons/UserProfileButtonSvg.vue'
// import YourWorksSvg from "@/assets/icons/YourWorksSvg.vue";
// import KatmanlarButtonSvg from "@/assets/icons/KatmanlarButtonSvg.vue";
import VectorAltliklariButtonSvg from '@/assets/icons/VectorAltliklariButtonSvg.vue'
// import EnvanterSvg from "@/assets/icons/EnvanterSvg.vue";
import HistorySvg from '@/assets/icons/HistorySvg.vue'

export default {
  props: {
    showProfileFunction: Function,
    showVectorCoastersFunction: Function,
  },
  components: {
    UserMenuButton,
    // YourWorksSvg,
    UserProfileButtonSvg,
    // KatmanlarButtonSvg,
    VectorAltliklariButtonSvg,
    // EnvanterSvg,
    HistorySvg,
  },
}
</script>

<style scoped>
.menuButtons {
  display: flex;
  flex-direction: column;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  user-select: none;
}

.menuText {
  /* border-bottom: 1px  solid; */
  text-decoration: underline #205c42 solid 1px;
  text-underline-offset: 3px;
  font-size: 1rem;
  margin: 1.1rem 0.4rem 0 0.4rem;
  user-select: none;
}

.menuButtons {
  margin: 0.5rem -0.6rem;
}

@media screen and (min-width: 3800px) {
  .menuContainer {
    margin-top: 2rem !important;
    margin-bottom: -1rem !important;
  }
  .menuText {
    text-underline-offset: 5px;
    text-decoration-thickness: 2px;
    font-size: 1.8rem;
    margin: 0 0.8rem;
  }
  .menuButtons {
    margin: 1.5rem 0;
  }
}

@media screen and (max-width: 1100px) {
  .menuText {
    font-size: 1rem;
  }
}

@media screen and (max-width: 770px) {
  .menuText {
    font-size: .95rem;
  }
}
</style>
