<template>
  <MapPage />
</template>

<script>
import MapPage from './pages/MapPage.vue'

export default {
  name: 'App',
  components: {
    MapPage,
  },
  created() {
    if (localStorage.getItem('data')) {
      const data = JSON.parse(localStorage.getItem('data'))
      this.$store.dispatch('setLoggedData', data)
    }
  },
}
</script>

<style>
@import 'ol/ol.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  background-color: #f9f9f9;
  color: #333;
  overflow: hidden;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#map,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
}
</style>
