<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    viewBox="0 0 20.556 11.19"
  >
    <path
      id="arrow-prev-small-svgrepo-com_1_"
      data-name="arrow-prev-small-svgrepo-com (1)"
      d="M18.13,5.87a1.558,1.558,0,0,0-2.2,0L8.314,13.491a3.115,3.115,0,0,0,0,4.4l7.618,7.618a1.558,1.558,0,1,0,2.2-2.2l-6.52-6.52a1.558,1.558,0,0,1,0-2.2L18.13,8.073A1.558,1.558,0,0,0,18.13,5.87Z"
      transform="translate(-5.414 18.592) rotate(-90)"
      fill="#a4a4a4"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
  },
};
</script>