<template>
  <button
    type="button"
    class="d-flex align-items-center justify-content-start btn"
    :style="{
      ...style,
      fontSize: textSize,
      width: '100%',
    }"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    style: Object,
  },
  data(){
    return {
      textSize: null
    }
  },
  mounted() {
    this.getScreen()
  },
  methods: {
    getScreen() {
      const getWidth = window.screen.width
      console.log('getWidth: ', getWidth)
     if (getWidth < 1100) {
        this.textSize = '11.5px'
      } else {
        this.textSize = '12.5px'
      }
    },
  },
};
</script>

<style scoped>
button {
  padding: 0.2rem 0.75rem;
}
</style>
