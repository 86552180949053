<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    viewBox="0 0 42.646 47.477"
  >
    <g id="Complete" transform="translate(2 2)">
      <g id="user">
        <g id="Group_59" data-name="Group 59">
          <path
            id="Path_116"
            data-name="Path 116"
            d="M42.646,29.492V24.662A9.662,9.662,0,0,0,32.985,15H13.662A9.662,9.662,0,0,0,4,24.662v4.831"
            transform="translate(-4 13.985)"
            fill="none"
            stroke="#3c4043"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="4"
          />
          <circle
            id="Ellipse_16"
            data-name="Ellipse 16"
            cx="9.656"
            cy="9.656"
            r="9.656"
            transform="translate(9.656)"
            fill="none"
            stroke="#3c4043"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="4"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
  },
};
</script>