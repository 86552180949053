<template>
  <KaratayAdaParsel />
  <KaratayRuhsatli />
  <KaratayRuhsatsiz />
  <BuyukSehirImar1000 />
  <BuyukSehirCDP />
  <BuyukSehirNIP />
</template>

<script>
import KaratayAdaParsel from "./KaratayAdaParsel.vue";
import KaratayRuhsatli from "./KaratayRuhsatli.vue";
import KaratayRuhsatsiz from "./KaratayRuhsatsiz.vue";
import BuyukSehirImar1000 from "./BuyukSehirImar1000.vue";
import BuyukSehirCDP from "./BuyukSehirCDP.vue";
import BuyukSehirNIP from "./BuyukSehirNIP.vue";

export default {
  components: {
    KaratayAdaParsel,
    KaratayRuhsatli,
    KaratayRuhsatsiz,
    BuyukSehirImar1000,
    BuyukSehirCDP,
    BuyukSehirNIP,
  },
  props: {
    mahalleler: Array,
    zones: Array,
    selectedFeatures: Object,
    featureSelected: Function,
    selectCondition: Function,
  },
};
</script>