const getModule = {
  state: {
    vector_layer: null,
  },
  mutations: {
    setSource(state, data) {
      state.vector_layer = data.vector_layer
    },
  },
  actions: {
    setSource({ commit }, data) {
      commit('setSource', data)
    },
  },
  getters: {},
}

export default getModule
