<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :height="height"
    viewBox="0 0 55.693 56.323"
    class="mb-1"
  >
    <path
      id="dig-dug-svgrepo-com"
      d="M33.6,17.8,22.324,29.239a15.506,15.506,0,0,0,7.864,4.775,7.935,7.935,0,0,0,5.143-.558c3.48,3.433,6.913,6.925,10.334,10.441L48.3,41.248,37.9,30.855a7.229,7.229,0,0,0,.535-5.084A16.206,16.206,0,0,0,33.6,17.8Zm14.86,1.128a17.6,17.6,0,0,0-3.267.321c13.91,8.374,24.3,19.564,32.5,32.512.333-7.032-2.518-14.278-7.044-20.158a5.653,5.653,0,0,1-6.961-6.961C59.017,21.09,53.672,18.9,48.457,18.928ZM34.9,22.837A10.641,10.641,0,0,1,36.246,26.2a4.928,4.928,0,0,1-1.022,4.621,4.959,4.959,0,0,1-4.609,1.022A10.811,10.811,0,0,1,27.242,30.5Zm31.3,1.889c-.855.836-.784,2.566.511,3.849s3.005,1.354,3.861.511a2.769,2.769,0,0,0-.511-3.861c-1.033-.966-2.744-1.6-3.861-.5Zm-6.688,8.458L22.3,70.411a4.368,4.368,0,0,0,2.91,2.958L62.45,36.117a29.739,29.739,0,0,0-2.934-2.934ZM54.384,47.33,51.64,50.074c3.861,4.039,7.721,8.113,11.57,12.176a34.885,34.885,0,0,1-5.524,4.288c6.64,6.652,14.836,9.277,19.243,6.474,2.708-4.431.131-12.651-6.474-19.255a43.079,43.079,0,0,1-4.217,5.429Z"
      transform="translate(-22.3 -17.8)"
      fill="#3c4043"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
    height: String,
  },
};
</script>


<style scoped>
svg {
  margin-top: 0.9rem;
}
</style>