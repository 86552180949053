<template>
  <svg xmlns="http://www.w3.org/2000/svg" :height="height" viewBox="0 0 75.084 56.287" class="mb-1">
    <g id="car-with-gps-svgrepo-com" transform="translate(0 -32.307)">
      <path
        id="Path_58"
        data-name="Path 58"
        d="M72.408,43.847a3.263,3.263,0,0,0-.864.12l-3.281.9-3.407-8.3a7.435,7.435,0,0,0-6.36-4.264H26.611a7.435,7.435,0,0,0-6.36,4.264l-3.4,8.289-3.24-.892a3.263,3.263,0,0,0-.864-.12,2.659,2.659,0,0,0-2.676,2.817V48.6c0,.079.006.157.012.235a21.683,21.683,0,0,0-3.775,3.011A21.528,21.528,0,1,0,36.75,82.288a21.435,21.435,0,0,0,5.161-8.281H61.966v4.118a3.459,3.459,0,0,0,3.455,3.455h4.512a3.459,3.459,0,0,0,3.455-3.455V61.636a25.916,25.916,0,0,0-1.626-8.244l-.55-1.339h.417A3.459,3.459,0,0,0,75.084,48.6V46.663A2.658,2.658,0,0,0,72.408,43.847ZM24.479,37.623a4.468,4.468,0,0,1,3.814-2.557H56.814a4.468,4.468,0,0,1,3.814,2.557l5.154,12.554a1.725,1.725,0,0,1-1.715,2.557H37.587c-.271-.3-.548-.6-.838-.89a21.388,21.388,0,0,0-15.223-6.305c-.1,0-.2.006-.3.007ZM21.528,82.151a15.085,15.085,0,1,1,10.666-4.418A14.986,14.986,0,0,1,21.528,82.151Zm45.21-16.1a1.386,1.386,0,0,1-1.382,1.382h-9.79a1.386,1.386,0,0,1-1.382-1.382v-4.7a1.386,1.386,0,0,1,1.382-1.382h9.79a1.386,1.386,0,0,1,1.382,1.382v4.7Z"
        transform="translate(0 0)"
        :fill="color ? color : '#3c4043'"
      />
      <path
        id="Path_59"
        data-name="Path 59"
        d="M37.5,140.182H35.021a.706.706,0,0,0-.705.705v.584a.706.706,0,0,0,.705.705h.368a.423.423,0,0,1,.423.423v1.167c0,.211-.06.222-.186.244a4.5,4.5,0,0,1-.752.055,3.016,3.016,0,0,1,.159-6.03,5.656,5.656,0,0,1,1.679.223.664.664,0,0,0,.859-.457l.207-.788a.572.572,0,0,0-.4-.753,7.7,7.7,0,0,0-2.317-.32c-3.435,0-5.663,2.042-5.677,5.2a4.8,4.8,0,0,0,1.409,3.626,5.528,5.528,0,0,0,4.038,1.349,11.054,11.054,0,0,0,2.941-.447.559.559,0,0,0,.435-.616v-4.166A.707.707,0,0,0,37.5,140.182Z"
        transform="translate(-20.836 -73.486)"
        :fill="color ? color : '#3c4043'"
      />
      <path
        id="Path_60"
        data-name="Path 60"
        d="M70.8,136.846a4.781,4.781,0,0,0-3.05-.807,20.006,20.006,0,0,0-2.6.147.526.526,0,0,0-.5.563v8.632a.706.706,0,0,0,.705.705h1.043a.706.706,0,0,0,.705-.705V142.84c0-.143.049-.185.217-.185l.063,0c.1,0,.212.006.326.006a4.637,4.637,0,0,0,3.246-1.06,3.258,3.258,0,0,0,.936-2.4A2.964,2.964,0,0,0,70.8,136.846ZM67.69,140.7c-.144,0-.27,0-.383-.011-.088-.005-.208-.013-.208-.307v-1.9a.524.524,0,0,1,.333-.51h.431c1,0,1.57.467,1.57,1.283C69.433,140.16,68.782,140.7,67.69,140.7Z"
        transform="translate(-45.84 -73.555)"
        :fill="color ? color : '#3c4043'"
      />
      <path
        id="Path_61"
        data-name="Path 61"
        d="M96.716,139.851c-1.28-.487-1.706-.733-1.706-1.234,0-.483.5-.794,1.268-.794a4.086,4.086,0,0,1,1.453.228.635.635,0,0,0,.86-.414l.24-.866a.516.516,0,0,0-.36-.68,6.355,6.355,0,0,0-2.149-.348c-2.278,0-3.808,1.23-3.808,3.06,0,1.351.927,2.358,2.754,2.994,1.145.411,1.532.722,1.532,1.234,0,.577-.509.909-1.4.909a5.369,5.369,0,0,1-1.956-.4.636.636,0,0,0-.233-.048c-.137,0-.384.057-.5.443l-.19.773a.785.785,0,0,0,.485.906,7.581,7.581,0,0,0,2.264.407c2.991,0,4.052-1.7,4.052-3.147C99.324,141.468,98.5,140.507,96.716,139.851Z"
        transform="translate(-65.593 -73.345)"
        :fill="color ? color : '#3c4043'"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
    height: String,
    color: String,
  },
}
</script>

<style scoped>
svg {
  margin-top: 0.9rem;
}
</style>
