<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    height="36.824"
    viewBox="0 0 30.128 36.824"
  >
    <path
      id="document-svgrepo-com"
      d="M13.533,1.49A1.674,1.674,0,0,1,14.717,1h13.39a5.021,5.021,0,0,1,5.021,5.021V32.8a5.021,5.021,0,0,1-5.021,5.021H8.021A5.021,5.021,0,0,1,3,32.8V12.717a1.674,1.674,0,0,1,.49-1.184ZM28.107,4.348H16.39v8.369a1.674,1.674,0,0,1-1.674,1.674H6.348V32.8a1.674,1.674,0,0,0,1.674,1.674H28.107A1.674,1.674,0,0,0,29.781,32.8V6.021A1.674,1.674,0,0,0,28.107,4.348Zm-19.392,6.7h4.328V6.715ZM9.7,21.086a1.674,1.674,0,0,1,1.674-1.674h13.39a1.674,1.674,0,1,1,0,3.348H11.369A1.674,1.674,0,0,1,9.7,21.086Zm0,6.7a1.674,1.674,0,0,1,1.674-1.674h13.39a1.674,1.674,0,1,1,0,3.348H11.369A1.674,1.674,0,0,1,9.7,27.781Z"
      transform="translate(-3 -1)"
      fill="#fff"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
  },
}
</script>
