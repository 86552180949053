<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    viewBox="0 0 29.686 31.035"
  >
    <path
      id="chat-svgrepo-com"
      d="M24.36,31.16a14.1,14.1,0,0,1-2.949-1.554,14.847,14.847,0,1,1,6.735-5.457c-.1,1.96.928,3.731,2,5.3.936,1.372.7,2.479-1.044,2.577A11.77,11.77,0,0,1,24.36,31.16ZM3.7,15.843A12.144,12.144,0,1,1,25.731,22.9c-.629.88-.305,2.528-.09,3.474a11.119,11.119,0,0,0,1.112,2.714,11.969,11.969,0,0,1-1.436-.447A11.653,11.653,0,0,1,22.4,26.99,1.349,1.349,0,0,0,21,26.84a12.151,12.151,0,0,1-17.306-11Zm5.4-4.048a1.349,1.349,0,0,0,0,2.7H22.59a1.349,1.349,0,1,0,0-2.7Zm0,5.4a1.349,1.349,0,1,0,0,2.7H22.59a1.349,1.349,0,1,0,0-2.7Z"
      transform="translate(-1 -1)"
      :fill="selectedFilter === 'Talepler' ? '#fff' : '#53dce1'"
      fill-rule="evenodd"
    />
  </svg>
</template>


<script>
export default {
  props: {
    width: String,
    selectedFilter: String,
  },
};
</script>