<template>
  <svg
    id="Group_117"
    data-name="Group 117"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    viewBox="0 0 28.777 29.387"
  >
    <path
      id="Path_256"
      data-name="Path 256"
      d="M28.09,3.747V1.1a1.1,1.1,0,0,0-1.1-1.1H11.6a1.1,1.1,0,0,0-1.1,1.1V3.747H4.906V4.972H7.473l2.785,22.477a2.21,2.21,0,0,0,2.193,1.938H26.137a2.21,2.21,0,0,0,2.193-1.938L31.116,4.972h2.567V3.747ZM14.04,25.465,12.824,9.6l1.221-.093,1.216,15.861Zm5.867-.047H18.682V9.557h1.224V25.418Zm4.642.047-1.221-.093L24.544,9.51l1.221.093Z"
      transform="translate(-4.906)"
      fill="#000"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
  },
};
</script>