<template>
  <div class="d-flex flex-column align-items-start justify-content-center">
    <label class="user-select-none">Telefon Numaranız</label>
    <div class="input-group">
      <span class="input-group-text">
        <CallSvg height="1rem" />
      </span>
      <input
        id="telNumber"
        name="telNumber"
        :value="telNumber"
        type="tel"
        placeholder="5xxxxxxxxxx"
        class="form-control"
        @focus="onInputFocus"
        @input="onInputChange"
      />
      <span class="input-group-text2">
        <InvalidCheckSvg height="1.55rem" />
      </span>
    </div>
  </div>
</template>

<script>
import CallSvg from "../../../assets/icons/CallSvg.vue";
import InvalidCheckSvg from "../../../assets/icons/InvalidCheckSvg.vue";

export default {
  props: {
    telNumber: String,
  },
  components: {
    CallSvg,
    InvalidCheckSvg,
  },
  methods: {
    onInputChange(input) {
      this.$emit("onInputChange", input);
    },
    onInputFocus(input) {
      this.$emit("onInputFocus", input);
    },
  },
};
</script>

<style scoped>
.input-group {
  margin-top: 0.5rem;
}

.input-group:focus-within span {
  background-color: #fff !important;
}

.form-control {
  border-left: 0;
  border-right: 0;
  background-color: #f3f3f3;
}

.form-control:focus {
  box-shadow: none;
  border-color: #dee2e6;
  border-right: 0;
}

.input-group-text {
  background-color: #fff !important;
  padding: 0.65rem;
  border-right: 0;
  color: #c8c8c8;
  background-color: #f3f3f3 !important;
}

.input-group-text2 {
  background-color: #f3f3f3 !important;
  padding: 0.65rem 0;
  border: 1px solid #dee2e6;
  border-left: 0;
  color: #c8c8c8;
}
</style>