<template>
  <div class="badge item1">
    <ShareSvg width="0.65rem" />
  </div>
</template>

<script>
import ShareSvg from '@/assets/icons/ShareSvg.vue'

export default {
  props: {},

  components: {
    ShareSvg,
  },
}
</script>

<style scoped>
.badge {
  background-color: red;
  display: inline-block;
  margin-left: 0.5rem;
  border-radius: 100%;
}

.item1 {
  background-color: #3d7ebf;
  padding: 0.4rem 0.5rem 0.37rem 0.4rem;
}

@media screen and (min-width: 3800px) {
  .item1 {
    padding: 0.6rem 0.7rem 0.57rem 0.6rem;
  }

  .item1 svg {
    width: 1.5rem;
  }
}
@media screen and (max-width: 770px) {
  .item1 {
    padding: 0.35rem 0.4rem 0.32rem 0.4rem;
  }
  .item1 svg {
    width: 0.45rem;
  }
}
</style>
