<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    viewBox="0 0 39.592 33.93"
  >
    <path
      id="done-v-svgrepo-com_1_"
      data-name="done-v-svgrepo-com (1)"
      d="M41.91,6.858a3.485,3.485,0,0,1,0,4.142L20.267,38.214a4.019,4.019,0,0,1-6.587,0l-10-12.572a3.484,3.484,0,0,1,0-4.142,2.01,2.01,0,0,1,3.294,0l10,12.572L38.616,6.858A2.01,2.01,0,0,1,41.91,6.858Z"
      transform="translate(-3 -6)"
      :fill="fill"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
    fill: String,
  },
};
</script>