<template>
  <div class="d-flex flex-column align-items-start justify-content-center mt-3">
    <label class="user-select-none">Parolanız</label>
    <div class="input-group">
      <span class="input-group-text">
        <LockSvg height="1.25rem" />
      </span>
      <input
        id="password"
        name="password"
        :value="password"
        type="password"
        placeholder="xxxxxxxx"
        class="form-control"
        @focus="onInputFocus"
        @input="onInputChange"
      />
      <span class="input-group-text2">
        <InvalidCheckSvg height="1.55rem" />
      </span>
    </div>
  </div>
</template>

<script>
import LockSvg from "../../../assets/icons/LockSvg.vue";
import InvalidCheckSvg from "../../../assets/icons/InvalidCheckSvg.vue";

export default {
  props: {
    password: String,
  },
  components: {
    LockSvg,
    InvalidCheckSvg,
  },
  methods: {
    onInputChange(input) {
      this.$emit("onInputChange", input);
    },
    onInputFocus(input) {
      this.$emit("onInputFocus", input);
    },
  },
};
</script>

<style scoped>
.input-group {
  margin-top: 0.5rem;
}

.input-group:focus-within span {
  background-color: #fff !important;
}

.form-control {
  border-left: 0;
  border-right: 0;
  background-color: #f3f3f3;
}

.form-control:focus {
  box-shadow: none;
  border-color: #dee2e6;
  border-right: 0;
}

.input-group-text {
  background-color: #f3f3f3;
  padding: 0.65rem;
  border-right: 0;
  color: #c8c8c8;
}

.input-group-text2 {
  background-color: #f3f3f3;
  padding: 0.65rem 0;
  border: 1px solid #dee2e6;
  border-left: 0;
  color: #c8c8c8;
}
@media screen and (min-width: 3800px) {
  .modal-container {
    width: 575px;
  }
  .input-group {
    width: 515px;
  }
}
</style>