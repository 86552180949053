<template>
  <ol-tile-layer
    v-if="
      $store.state.karatay_ruhsatsiz.type === 'KaratayWS:kbs_yapa_ruhsatsiz'
    "
  >
    <ol-source-tile-wms
      :url="$store.state.karatay_ruhsatsiz.url"
      :layers="$store.state.karatay_ruhsatsiz.type"
    ></ol-source-tile-wms>
  </ol-tile-layer>
</template>