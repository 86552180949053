<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 41 41"
  >
    <path
      id="draw-alt-svgrepo-com"
      d="M7.222,32.556V11.444m0,21.111a4.222,4.222,0,1,0,4.222,4.222M7.222,32.556a4.222,4.222,0,0,1,4.222,4.222M7.222,11.444a4.222,4.222,0,0,0,4.222-4.222M7.222,11.444a4.222,4.222,0,1,1,4.222-4.222m0,0H32.556m0,0A4.222,4.222,0,1,0,36.778,3,4.222,4.222,0,0,0,32.556,7.222ZM11.444,36.778H32.556m0,0a4.222,4.222,0,1,0,4.222-4.222A4.222,4.222,0,0,0,32.556,36.778Zm1.952-26.022-5.762,7.819m0,6.85L34.5,33.236M30.444,22a4.222,4.222,0,1,1-4.222-4.222A4.222,4.222,0,0,1,30.444,22Z"
      transform="translate(-1.5 -1.5)"
      fill="none"
      :stroke="fill"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="3"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
    height: String,
    fill: String,
  },
};
</script>