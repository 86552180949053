<template>
  <ol-vector-layer :styles="vectorStyle" ref="sourceRef">
    <ol-source-vector :projection="projection">
      <ol-feature>
        <ol-interaction-modify v-if="modifyEnabled" :features="selectedFeatures"> </ol-interaction-modify>
        <ol-interaction-draw v-if="drawEnable" :type="drawType" @drawend="drawend" @drawstart="drawstart">
          <ol-style>
            <ol-style-stroke color="blue" :width="2"></ol-style-stroke>
            <ol-style-fill color="rgba(255, 255, 255, 0.4)"></ol-style-fill>
          </ol-style>
        </ol-interaction-draw>
        <ol-interaction-snap v-if="modifyEnabled || drawEnable" />
      </ol-feature>

      <div v-for="(item, index) in this.$store.state.layer.vector?.data" :key="index">
        <div v-if="item?.type == 'circle'">
          <ol-feature>
            <ol-geom-circle :center="item?.data?.center" :radius="item?.data?.radius"></ol-geom-circle>
            <ol-style>
              <ol-style-stroke :color="item?.color" :width="2"></ol-style-stroke>
              <ol-style-fill color="rgba(255,255,255,0.1)"></ol-style-fill>
            </ol-style>
          </ol-feature>
        </div>
        <div v-if="item?.type == 'line'">
          <ol-feature>
            <ol-geom-line-string :coordinates="item?.data?.coordinates"></ol-geom-line-string>
            <ol-style>
              <ol-style-stroke :color="item?.color" :width="2"></ol-style-stroke>
              <ol-style-fill color="rgba(255,255,255,0.1)"></ol-style-fill>
            </ol-style>
          </ol-feature>
        </div>
        <div v-if="item?.type == 'polygon'">
          <ol-feature>
            <ol-geom-polygon :coordinates="item?.data?.coordinates"></ol-geom-polygon>
            <ol-style>
              <ol-style-stroke :color="item?.color" :width="2"></ol-style-stroke>
              <ol-style-fill color="rgba(255,255,255,0.1)"></ol-style-fill>
            </ol-style>
          </ol-feature>
        </div>
      </div>

      <!-- <ol-geom-circle
          :center="[32.68416878193359, 37.90406745788575]"
          :radius="0.02539254173279204"
        ></ol-geom-circle>
        <ol-style>
          <ol-style-stroke color="green" :width="2"></ol-style-stroke>
          <ol-style-fill color="rgba(255,255,255,0.1)"></ol-style-fill>
        </ol-style> -->
    </ol-source-vector>

    <ol-style>
      <ol-style-stroke color="#205c42" :width="2"></ol-style-stroke>
      <ol-style-fill color="rgba(255,255,255,0.1)"></ol-style-fill>
      <ol-style-circle :radius="7">
        <ol-style-fill color="green"></ol-style-fill>
      </ol-style-circle>
    </ol-style>
  </ol-vector-layer>
  <ol-interaction-select
    v-if="zones?.length !== 0 || mahallelerVisible === true"
    @select="featureSelected"
    :condition="selectCondition"
    :features="selectedFeatures"
  >
    <ol-style>
      <ol-style-stroke :color="'red'" :width="2"></ol-style-stroke>
      <ol-style-fill :color="`rgba(255, 0, 0, 0.4)`"></ol-style-fill>
    </ol-style>
  </ol-interaction-select>
</template>

<script>
import { Fill, Stroke, Style } from "ol/style"

export default {
  props: {
    projection: String,
    drawEnable: Boolean,
    modifyEnabled: Boolean,
    showProject: Boolean,
    mahallelerVisible: Boolean,
    drawType: String,
    drawend: Function,
    drawstart: Function,
    selectedFeatures: Object,
    featureSelected: Function,
    selectCondition: Function,
    zones: Array,
  },
  mounted() {
    this.$store.dispatch("setSource", {
      vector_layer: this.$refs.sourceRef,
    })
  },
  methods: {
    vectorStyle() {
      const style = new Style({
        stroke: new Stroke({
          color: "blue",
          width: 3,
        }),
        fill: new Fill({
          color: "rgba(0, 0, 255, 0.4)",
        }),
      })
      return style
    },
  },
}
</script>
