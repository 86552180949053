<template>
  <div class="position-relative user-select-none">
    <div
      class="offcanvas offcanvas-end border-0"
      :class="vectorCoasterNew ? 'show' : ''"
      tabindex="-1"
      :style="{
        visibility: vectorCoasterNew ? 'visible' : 'hidden',
        width: vectorCoasterNew ? screenSize ?? '20%' : '0%',
      }"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <div class="container">
        <div class="closeCanvas" @click="btnClose">
          <ArrowLeftSvg width="0.5rem" />
        </div>
        <div class="row user-select-none">
          <div class="col-12 d-flex justify-content-start align-items-center">
            <div class="title">Yeni Katman Oluştur</div>
          </div>
        </div>
        <div class="row ps-3 pe-3">
          <div class="col-12">
            <label for="altlik_adi" class="altlik_adi_class user-select-none">Katman Adı</label>
            <div class="text">
              <input type="text" id="altlik_adi" class="form-control altlik_adi" placeholder="Örn. Ziyaret Edilecek Yerler" v-model="altlik_adi" />
              <div class="text_icon">
                <YeniAltlikAdiInputSvg width="1.5rem" />
              </div>
            </div>
          </div>
          <div class="col-12">
            <label for="altlik_aciklama" class="altlik_adi_class user-select-none">Katman Açıklama</label>
            <div class="text">
              <textarea type="text" id="altlik_aciklama" class="form-control altlik_adi" placeholder="Örn. Ziyaret Edilecek Yerler" v-model="altlik_aciklama" rows="10"></textarea>
              <div class="text_icon text_icon_start">
                <YeniAltlikAdiInputSvg width="1.5rem" />
              </div>
            </div>
          </div>
          <div class="col-12">
            <label for="altlik_adi" class="altlik_adi_class user-select-none">İkon Seçimi</label>
            <div class="icons">
              <div v-for="(icon, index) in icons" :key="index">
                <div class="icon" v-bind:class="selectedIcon == icon ? 'active' : ''" @click="selectIcon(icon)">
                  <v-icon :name="icon" scale="1.15" />
                </div>
              </div>
            </div>
          </div>
          <button
            class="block"
            :disabled="!(this.$store.state?.layer?.new_vector?.length > 0)"
            @click="saveVector"
            :style="{
              borderColor: !(this.$store.state?.layer?.new_vector?.length > 0) ? '#a4a4a4' : ' #2aa83f',
              color: !(this.$store.state?.layer?.new_vector?.length > 0) ? '#a4a4a4' : '#2aa83f',
            }"
          >
            <CheckSvg width="1rem" class="me-2" :fill="!(this.$store.state?.layer?.new_vector?.length > 0) ? '#a4a4a4' : '#2aa83f'" />Kaydet
          </button>
          <button class="virtualKeyboard" @click="openKeyboard">
            <VirtualKeyboardSvg height="1rem" />
            Sanal Klavye Kullan
          </button>
        </div>
      </div>
      <!-- <div class="footer-logo">
        <FooterLogoWhiteSvg height="2.25rem" />
      </div> -->
    </div>
  </div>
</template>

<script>
// import FooterLogoWhiteSvg from "@/assets/icons/FooterLogoWhiteSvg.vue";
import ArrowLeftSvg from '@/assets/icons/ArrowLeftSvg.vue'
import YeniAltlikAdiInputSvg from '@/assets/icons/YeniAltlikAdiInputSvg.vue'
import VirtualKeyboardSvg from '@/assets/icons/VirtualKeyboardSvg.vue'
import CheckSvg from '@/assets/icons/CheckSvg.vue'
import { axiosInstance } from '@/helpers'
import axios from 'axios'

export default {
  props: {
    openKeyboard: Function,
    inputs: Object,
    vectorCoasterNew: Boolean,
  },

  emits: ['onInputChange', 'onInputFocus', 'btnClose'],
  components: {
    // FooterLogoWhiteSvg,
    ArrowLeftSvg,
    YeniAltlikAdiInputSvg,
    VirtualKeyboardSvg,
    CheckSvg,
  },
  mounted() {
    this.getScreen()
  },
  methods: {
    test() {
      console.log('TEST')
    },
    getScreen() {
      const getWidth = window.screen.width
      console.log('getWidth: ', getWidth)
      if (getWidth < 1500 && getWidth > 1100) {
        this.screenSize = '27%'
      } else if (getWidth < 1100) {
        this.screenSize = '32.5%'
      } else {
        this.screenSize = '20%'
      }
    },
    selectIcon(icon) {
      this.selectedIcon = icon
    },
    btnClose() {
      this.$emit('btnClose')
      this.openKeyboard('close')
    },
    onInputChange(input) {
      this.$emit('onInputChange', input)
    },
    onInputFocus(input) {
      this.$emit('onInputFocus', input)
    },
    saveVector() {
      const data = {
        name: this.altlik_adi,
        description: this.altlik_aciklama,
        icon: this.selectedIcon,
        data: this.$store.state?.layer?.new_vector,
      }

      axios
        .post(process.env.VUE_APP_BACKEND_URL + '/layer/data', data, {
          headers: {
            Authorization: this.$store.state.auth.token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            location.reload()
          }
        })
    },
  },
  data() {
    return {
      altlik_adi: '',
      altlik_aciklama: '',
      selectedIcon: null,
      icons: [
        'io-save-outline',
        'io-business-outline',
        'io-build-outline',
        'io-car-outline',
        'io-bus-outline',
        'io-earth-outline',
        'io-cog-outline',
        'io-calendar-outline',
        'io-cube-outline',
        'io-briefcase-outline',
        'io-folder-outline',
        'io-basketball-outline',
        'io-airplane-outline',
      ],
    }
  },
}
</script>

<style scoped>
.offcanvas {
  padding-top: 2rem;
}

.footer-logo {
  position: absolute;
  bottom: 30px;
  align-self: center;
}

.closeCanvas {
  background-color: #f8f8f8;
  padding: 0.5rem 1rem;
  margin: 1rem 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  max-width: 2.5rem;
}

.title {
  font-size: 1.2rem;
  text-decoration: 1px #205c42 solid underline;
  text-underline-offset: 3px;
  margin: 0 0 1rem 1rem;
}

.block {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  background-color: #fff;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  user-select: none;
  padding: 1.2rem 0;
  margin: 1.2rem 0;
}

.text {
  position: relative;
  user-select: none;
}

.altlik_adi_class {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1.1rem;
}

.altlik_adi {
  margin-top: 0.2rem;
  margin-bottom: 1.25rem;
  padding: 12.5px 50px;
  color: #7a7a7a;
  font-size: 0.85rem;
}

.altlik_adi::placeholder {
  color: #dedede;
}

.altlik_adi:focus {
  box-shadow: none;
  border-color: #dee2e6;
}

.text_icon {
  position: absolute;
  top: -2px;
  bottom: 0;
  left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
}

.text_icon_start {
  align-items: flex-start;
  top: 10px;
}

.icons {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 0.5rem;
}

.icon {
  border: 1px solid #cecece;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 8px;
  cursor: pointer;
}

.icon.active {
  background-color: #83e386;
}

.virtualKeyboard {
  display: block;
  padding: 0.8rem 0;
  border: none;
  background-color: #ececec;
  color: #000;
  border-radius: 8px;
  text-align: center;
  user-select: none;
}
@media screen and (min-width: 3800px) {
  .footer-logo {
    bottom: 30px;
  }
  .offcanvas {
    padding-top: 1.9rem;
  }
  .footer-logo svg {
    height: 3.5rem;
  }
  .closeCanvas {
    padding: 1.5rem 3rem 1.5rem 2rem;
  }
  .closeCanvas svg {
    width: 0.85rem;
  }
  .title {
    font-size: 1.8rem;
    text-decoration: 3px #205c42 solid underline;
    text-underline-offset: 5px;
  }

  .altlik_adi {
    margin-top: 0.2rem;
    margin-bottom: 1.25rem;
    padding: 15.5px 80px;
    color: #7a7a7a;
    font-size: 1.2rem;
  }
  .altlik_adi_class {
    font-size: 1.8rem;
    margin-top: 2rem;
  }
  .text_icon {
    width: 4rem;
  }

  .text_icon svg {
    width: 2rem;
  }
  .icons {
    gap: 20px;
    margin: 0.5rem 0 2rem 0;
  }
  .icon {
    padding: 24px 32px;
  }
  .icon i {
    transform: scale(2.1);
  }
  .block {
    font-size: 26px;
  }

  .block svg {
    width: 1.7rem;
  }
  .virtualKeyboard {
    padding: 1.2rem 0;
    font-size: 30px;
    z-index: 999;
  }
}

@media screen and (max-width: 1500px) {
  .offcanvas.offcanvas-end {
    width: 27%;
  }
}

@media screen and (max-width: 1100px) {
  .offcanvas.offcanvas-end {
    width: 32.5%;
  }
}

@media screen and (max-width: 770px) {
  .closeCanvas {
    padding: 0.3rem 1rem;
    margin: 0.6rem 0.3rem;
    max-width: 2.5rem;
  }
  .closeCanvas svg {
    width: 0.4rem;
  }
  .title {
    font-size: 0.9rem;
  }
  .altlik_adi_class {
    /* font-weight: 400; */
    font-size: 0.8rem;
  }
  .altlik_adi {
    font-size: 10px;
  }
  .text_icon {
    width: 20px;
  }
  .text_icon svg {
    width: 0.9rem;
  }
  .form-control {
    padding: 7.5px 35px;
  }
  .icon svg {
    width: 1rem;
    /* padding: 3px 8px; */
  }
  .block {
    font-size: 12px;
    text-align: center;
  }
  .block svg {
    width: 0.7rem;
    margin-right: 0.5rem;
    padding-bottom: 0.1rem;
  }
  .virtualKeyboard {
    padding: 0.3rem 0;
    font-size: 12px;
  }
  .virtualKeyboard svg {
    width: 0.85rem;
  }
  .inputs {
    margin: 0 0 0 1rem;
  }
  .simple-keyboard {
    max-width: 250px;
    margin-top: 5rem;
    margin-left: auto;
    margin-right: 0;
    color: #000;
    z-index: 100;
  }

  .simple-keyboard:focus {
    box-shadow: none;
    border-color: #dee2e6;
    border-right: 0;
  }
}
</style>
