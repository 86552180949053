<template>
  <div class="d-flex flex-column">
    <Transition name="slide-fade">
      <div class="d-flex flex-column buttonscontainer" v-if="bsKatmanlar">
        <button
          @click="
            $store.dispatch('setBuyukSehirImar1000', {
              url:
                this.getBackendUrl() + '/map/buyuksehir/buyuksehir-imar-1000',
              type: 'buyuksehir-imar-1000',
            })
          "
          type="button"
          class="buyuk-sehir-katmanlar-test d-flex flex-column align-items-center justify-content-between btn btn-light"
          :class="
            $store.state.buyuksehir_imar_1000.type == 'buyuksehir-imar-1000' &&
            'buyuk-sehir-katmanlar-active'
          "
        >
          <div class="selectedDiv">
            <div
              class="selected"
              :style="{
                backgroundColor:
                  $store.state.buyuksehir_imar_1000.type ==
                  'buyuksehir-imar-1000'
                    ? '#5B5B5B'
                    : '#fff',
              }"
            ></div>
          </div>
          <BuyukSehirLogoSvg width="2.58rem" />
          Büyükşehir<br />
          İmar
        </button>
        <button
          @click="
            $store.dispatch('setBuyukSehirCDP', {
              url: this.getBackendUrl() + '/map/buyuksehir/buyuksehir-cdp',
              type: 'buyuksehir-cdp',
            })
          "
          type="button"
          class="buyuk-sehir-katmanlar-test d-flex flex-column align-items-center justify-content-between btn btn-light"
          :class="
            $store.state.buyuksehir_cdp.type == 'buyuksehir-cdp' &&
            'buyuk-sehir-katmanlar-active'
          "
        >
          <div class="selectedDiv">
            <div
              class="selected"
              :style="{
                backgroundColor:
                  $store.state.buyuksehir_cdp.type == 'buyuksehir-cdp'
                    ? '#5B5B5B'
                    : '#fff',
              }"
            ></div>
          </div>
          <BuyukSehirLogoSvg width="2.58rem" />
          Büyükşehir<br />
          CDP
        </button>
        <button
          @click="
            $store.dispatch('setBuyukSehirNIP', {
              url: this.getBackendUrl() + '/map/buyuksehir/buyuksehir-nip',
              type: 'buyuksehir-nip',
            })
          "
          type="button"
          class="buyuk-sehir-katmanlar-test d-flex flex-column align-items-center justify-content-between btn btn-light"
          :class="
            $store.state.buyuksehir_nip.type == 'buyuksehir-nip' &&
            'buyuk-sehir-katmanlar-active'
          "
        >
          <div class="selectedDiv">
            <div
              class="selected"
              :style="{
                backgroundColor:
                  $store.state.buyuksehir_nip.type == 'buyuksehir-nip'
                    ? '#5B5B5B'
                    : '#fff',
              }"
            ></div>
          </div>
          <BuyukSehirLogoSvg width="2.58rem" />
          Büyükşehir<br />
          NIP
        </button>
      </div>
    </Transition>
    <button
      @click="openBsKatmalar"
      type="button"
      class="buyuk-sehir-katmanlar d-flex flex-column align-items-center justify-content-between btn btn-light"
    >
      <BuyukSehirLogoSvg width="2.58rem" />
      Büyükşehir
      <span>Katmanları</span>
    </button>
  </div>
</template>

<script>
import BuyukSehirLogoSvg from '../../../assets/icons/BuyukSehirLogoSvg.vue'

export default {
  props: {
    bsKatmanlar: Boolean,
    openBsKatmalar: Function,
  },
  components: {
    BuyukSehirLogoSvg,
  },
  methods: {
    getBackendUrl() {
      return process.env.VUE_APP_BACKEND_URL
    },
  },
}
</script>

<style scoped>
.buttonscontainer {
  max-height: 28.9rem;
  overflow-y: scroll;
  overflow-x: none;
}

.buttonscontainer::-webkit-scrollbar {
  display: none;
  scroll-padding-right: -100px;
}

.buyuk-sehir-katmanlar {
  line-height: 1;
  padding-top: 0.5rem;
  padding-bottom: 0.7rem;
  border-bottom-width: 0.3rem;
  border-bottom-color: #ffaa00;
  margin-left: 0.8rem;
  margin-top: 0.8rem;
}

.buyuk-sehir-katmanlar-active {
  border: 0.15rem solid #205c42 !important;
}

.buyuk-sehir-katmanlar:hover {
  border-bottom-color: #ffaa00;
}

.buyuk-sehir-katmanlar-test {
  position: relative;
  line-height: 1;
  padding-top: 0.5rem;
  padding-bottom: 0.7rem;
  border-bottom-width: 0.3rem;
  border-bottom-color: #5b5b5b;
  margin-left: 0.8rem;
  margin-top: 0.8rem;
}

.buyuk-sehir-katmanlar-test:hover {
  border-bottom-color: #5b5b5b;
}

.selectedDiv {
  position: absolute;
  right: 10px;
  top: 5px;
  border-radius: 50%;
  border: 1px solid #707070;
  width: 15px;
  height: 15px;
}

.selected {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #fff;
}

.slide-fade-enter-active {
  transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.5s;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(580px);
  opacity: 0;
}

@media only screen and (min-width: 3800px) {
  .buttonscontainer {
    max-height: 39rem;
    overflow-y: scroll;
    overflow-x: none;
  }
  .buyuk-sehir-katmanlar {
    padding: 1rem 2rem;
  }
  .buyuk-sehir-katmanlar svg {
    width: 4rem;
  }

  .buyuk-sehir-katmanlar-test {
    padding: 1rem 2rem;
  }
  .buyuk-sehir-katmanlar-test svg {
    width: 4rem;
  }
}
@media only screen and (max-width: 1500px) {
  button {
    margin-left: 0.5rem;
    font-size: 14px;
  }
}
@media only screen and (max-width: 1100px) {
  .selectedDiv {
    right: 5px;
    width: 10px;
    height: 10px;
  }
  .buttonscontainer {
    max-height: 38rem;
    overflow-y: scroll;
    overflow-x: none;
  }
  .buyuk-sehir-katmanlar {
    padding-top: 0.4rem;
    padding-bottom: 0.3rem;
    font-size: 11px;
  }
  .buyuk-sehir-katmanlar svg {
    height: 1.58rem;
  }

  .buyuk-sehir-katmanlar-test {
    padding-top: 0.4rem;
    padding-bottom: 0.3rem;

    font-size: 11px;
  }
  .buyuk-sehir-katmanlar-test svg {
    height: 1.58rem;
  }
}

@media only screen and (max-width: 770px) {
  .selectedDiv {
    right: 5px;
    width: 10px;
    height: 10px;
  }
  .buttonscontainer {
    max-height: 38rem;
    overflow-y: scroll;
    overflow-x: none;
  }
  .buyuk-sehir-katmanlar {
    padding-top: 0.4rem;
    padding-bottom: 0.3rem;
    font-size: 10px;
  }
  .buyuk-sehir-katmanlar svg {
    height: 1.4rem;
  }

  .buyuk-sehir-katmanlar-test {
    padding-top: 0.4rem;
    padding-bottom: 0.3rem;
    font-size: 10px;
  }
  .buyuk-sehir-katmanlar-test svg {
    height: 1.4rem;
  }
}
</style>
