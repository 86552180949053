<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :class="myclass"
    viewBox="0 0 52 37"
  >
    <g id="Group_104" data-name="Group 104" transform="translate(-3371 -98)">
      <rect
        id="Rectangle_36"
        data-name="Rectangle 36"
        width="52"
        height="7"
        rx="3.5"
        transform="translate(3371 98)"
        fill="#3c4043"
      />
      <rect
        id="Rectangle_37"
        data-name="Rectangle 37"
        width="52"
        height="7"
        rx="3.5"
        transform="translate(3371 113)"
        fill="#3c4043"
      />
      <rect
        id="Rectangle_38"
        data-name="Rectangle 38"
        width="52"
        height="7"
        rx="3.5"
        transform="translate(3371 128)"
        fill="#3c4043"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
    myclass: String,
  },
};
</script>