<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    height="34.613"
    viewBox="0 0 34.613 34.613"
  >
    <path
      id="calendar-lines-svgrepo-com"
      d="M3,13.7H35.113m-7.136,7.139-17.84,0m5.947,7.137H10.136M10.136,3V6.568M27.976,3V6.568M8.709,35.113H29.4c2,0,3,0,3.761-.389a3.567,3.567,0,0,0,1.559-1.559c.389-.763.389-1.762.389-3.761V12.277c0-2,0-3-.389-3.761a3.568,3.568,0,0,0-1.559-1.559C32.4,6.568,31.4,6.568,29.4,6.568H8.709c-2,0-3,0-3.761.389A3.568,3.568,0,0,0,3.389,8.516C3,9.28,3,10.279,3,12.277V29.4c0,2,0,3,.389,3.761a3.568,3.568,0,0,0,1.559,1.559C5.711,35.113,6.711,35.113,8.709,35.113Z"
      transform="translate(-1.75 -1.75)"
      fill="none"
      stroke="#a4a4a4"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.5"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
  },
};
</script>