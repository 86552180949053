<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 35 35"
  >
    <path
      id="image-svgrepo-com"
      d="M1.934,9.82A7.887,7.887,0,0,1,9.82,1.933H29.047A7.887,7.887,0,0,1,36.933,9.82V29.047a7.886,7.886,0,0,1-7.886,7.887H9.82a7.887,7.887,0,0,1-7.887-7.887V19.468c0-.023,0-.046,0-.068Zm32.044,0v1.76c-8.534.388-14.105,4.137-17.55,8.834A14.637,14.637,0,0,0,4.889,17.531V9.82A4.931,4.931,0,0,1,9.82,4.889H29.047A4.931,4.931,0,0,1,33.978,9.82ZM4.889,29.047V20.539a11.723,11.723,0,0,1,9.938,2.383A27.12,27.12,0,0,0,11.6,33.978H9.82A4.931,4.931,0,0,1,4.889,29.047ZM17.96,23.419a23.942,23.942,0,0,0-3.4,10.56H29.047a4.931,4.931,0,0,0,4.931-4.931V14.539C25.687,14.955,20.788,18.875,17.96,23.419ZM12.223,13.024a2.4,2.4,0,1,0-2.4-2.4A2.4,2.4,0,0,0,12.223,13.024Z"
      transform="translate(-1.933 -1.933)"
      :fill="activeIndex === 2 ? '#fff' : '#205c42'"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
    height: String,
    activeIndex: Number,
  },
};
</script>