<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="54"
    :height="height"
    viewBox="0 0 54 54"
  >
    <circle
      id="Ellipse_2"
      data-name="Ellipse 2"
      cx="27"
      cy="27"
      r="27"
      fill="#dcdcdc"
    />
    <path
      id="done-v-svgrepo-com"
      d="M34.18,6.547a1.866,1.866,0,0,1,0,2.639L16.837,26.529a3.732,3.732,0,0,1-5.279,0L3.547,18.517a1.866,1.866,0,1,1,2.639-2.639L14.2,23.89,31.541,6.547A1.866,1.866,0,0,1,34.18,6.547Z"
      transform="translate(8.251 10.242)"
      fill="#b1b1b1"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: String,
  },
};
</script>