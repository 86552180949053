import { axiosInstance } from "../../helpers/index"
import axios from "axios"

const getModule = {
  state: {
    show: false,
    data: [],
  },
  mutations: {
    setArventoData(state, data) {
      state.data = data
    },
    setArventoToggle(state, data) {
      state.show = data
    },
  },
  actions: {
    setArventoData({ commit }) {
      if (this.state.auth.token) {
        axiosInstance()
          .get(process.env.VUE_APP_BACKEND_URL + "/arvento/index", {
            headers: {
              Authorization: this.state.auth.token,
            },
          })
          .then((res) => {
            commit("setArventoData", res.data)
          })
      }
    },
    setArventoToggle({ commit }, data) {
      commit("setArventoToggle", data)
    },
  },
  getters: {
    allArvento: (state) => state.data,
  },
}

export default getModule
