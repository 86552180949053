import axios from 'axios'

// Base URL'i ayarlayın
const baseURL = process.env.VUE_APP_BACKEND_URL // Burayı kendi API'nizin URL'i ile değiştirin

// Token'ı almak için bir fonksiyon
const getAuthToken = async () => {
  let token = ''
  let data = {}

  try {
    data = await localStorage.getItem('data')
    data = JSON.parse(data)
    token = data?.token
  } catch (error) {
    data = {}
    token = data?.token
  }

  // Eğer token yoksa, burada bir işlem yapabilirsiniz, örneğin login ekranına yönlendirme
  if (!token) {
    throw new Error('Token bulunamadı!')
  }

  return token
}

// Axios örneğini oluşturun ve yapılandırın
const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
})

// Axios instance için request interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    // Token'ı almak için bekle
    const token = await getAuthToken()
    // Token'ı header'a ekle
    config.headers['Authorization'] = `${token}`
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Yanıt öncesi işlemler (opsiyonel)
axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    // Global hata işlemleri
    return Promise.reject(error)
  }
)

export default axiosInstance
