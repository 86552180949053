const getModule = {
  state: {
    logged: false,
    token: "",
    name: "",
    surname: "",
    phone: "",
    identification_number: "",
    management: "",
    authorization_level: "",
  },
  mutations: {
    setLoggedData(state, data) {
      state.logged = true
      state.token = data.token
      state.name = data.name
      state.surname = data.surname
      state.phone = data.phone
      state.identification_number = data.identification_number
      state.management = data.management
      state.authorization_level = data.authorization_level
    },
    setLogout(state) {
      state.logged = false
      state.token = ""
      state.name = ""
      state.surname = ""
      state.phone = ""
      state.identification_number = ""
      state.management = ""
      state.authorization_level = ""
    },
  },
  actions: {
    setLoggedData({ commit }, data) {
      commit("setLoggedData", data)
    },
    setLogout({ commit }) {
      commit("setLogout")
    },
  },
  getters: {
    allAuth: (state) => state,
  },
}

export default getModule
