<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    viewBox="0 0 36.389 30.324"
  >
    <g id="identity-svgrepo-com" transform="translate(-2 -6)">
      <path
        id="Path_208"
        data-name="Path 208"
        d="M35.357,8H26.942s.076.531.076.758a5.274,5.274,0,0,1-5.307,5.307H17.162a5.274,5.274,0,0,1-5.307-5.307c0-.227,0-.758.076-.758h-6.9A3.041,3.041,0,0,0,2,11.032V33.776a3.041,3.041,0,0,0,3.032,3.032H35.357a3.041,3.041,0,0,0,3.032-3.032V11.032A3.041,3.041,0,0,0,35.357,8ZM16.935,30.743H8.292a1.848,1.848,0,0,1-1.744-1.9c0-1.516,1.592-2.35,3.26-3.032,1.137-.455,1.289-.91,1.289-1.44a1.977,1.977,0,0,0-.682-1.289,3.316,3.316,0,0,1-1.061-2.5,3.2,3.2,0,1,1,6.368,0,3.316,3.316,0,0,1-1.061,2.5,1.977,1.977,0,0,0-.682,1.289c0,.455.152.91,1.289,1.44,1.668.682,3.26,1.516,3.26,3.032A1.639,1.639,0,0,1,16.935,30.743ZM33.84,27.711a.716.716,0,0,1-.758.758H22.469a.716.716,0,0,1-.758-.758V26.194a.716.716,0,0,1,.758-.758H33.082a.716.716,0,0,1,.758.758Zm1.516-6.065a.716.716,0,0,1-.758.758H22.469a.716.716,0,0,1-.758-.758V20.13a.716.716,0,0,1,.758-.758H34.6a.716.716,0,0,1,.758.758Z"
        transform="translate(0 -0.484)"
        fill="#a4a4a4"
      />
      <path
        id="Path_209"
        data-name="Path 209"
        d="M21.274,10.549h4.549a2.274,2.274,0,1,0,0-4.549H21.274a2.274,2.274,0,1,0,0,4.549Z"
        transform="translate(-4.112)"
        fill="#a4a4a4"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
  },
};
</script>