<template>
  <div
    class="container-fluid"
    :class="showProject || showVehicle || showUser || showProfile || showVectorCoasters || vectorCoasterNew ? 'headerMap' : 'headerMapWidth'"
    :style="{
      transition: 'width 0.3s',
    }"
  >
    <div class="karatay-logo">
      <KaratayLogoVue :showProject="showProject" :showVehicle="showVehicle" :showUser="showUser" :showProfile="showProfile" :showVectorCoasters="showVectorCoasters" :vectorCoasterNew="vectorCoasterNew" />
    </div>

    <div class="search-panel">
      <div class="d-flex flex-column">
        <div class="d-flex">
          <div class="input-group">
            <span class="input-group-text border-0">
              <SearchSvg height="1rem" />
            </span>
            <input id="searchInput" name="searchInput" type="text" :value="inputs.searchInput" :placeholder="placeholderChange" class="form-control border-0" @focus="onInputFocus" @input="onInputChange" />
            <button type="button" class="input-group-text btn btn-link" @click="showFilterFunction(!showFilter)">
              <FilterSvg width="1rem" height="1rem" />
            </button>
          </div>

          <button @click="openKeyboard" type="button" class="locatinButton btn btn-light bg-white ms-1">
            <VirtualKeyboardSvg width="1.3rem" />
          </button>
          <button @click="btnKonumGetir" type="button" class="locatinButton btn btn-light bg-white ms-1">
            <LocationSvg width="1.3rem" />
          </button>
        </div>
        <HeaderFilters
          :showFilterFunction="showFilterFunction"
          :showFilter="showFilter"
          :selectFilter="selectFilter"
          :selectedFilter="selectedFilter"
          :showProject="showProject"
          :showVehicle="showVehicle"
          :showUser="showUser"
          :showProfile="showProfile"
          :showVectorCoasters="showVectorCoasters"
        />
      </div>
    </div>

    <div class="profile-panel">
      <div class="d-flex flex-column userPanel">
        <div class="d-flex justify-content-end gap-3">
          <button v-if="!showUser && !showProfile && !showVectorCoasters" type="button" class="login btn btn-light" id="show-modal" @click="loginUser">
            <LoginSvg width="0.9rem" />

            {{ !$store.state.auth.logged ? ' Kullanıcı Girişi' : $store.state.auth.name }}

            <LoginMenuSvg width="1.3rem" v-if="this.$store.state.auth.logged" myclass="loginMenuSvg ms-3" />
          </button>
          <button v-if="this.$store.state.auth.logged" type="button" class="btn btn-light">
            <SettingsSvg width="1rem" />
            Ayarlar
          </button>
          <!-- <button type="button" class="btn btn-light" @click="closeMap">
                X
              </button> -->
        </div>
        <SimpleKeyboard v-if="keyboardVisible" @onChange="onChange" @onKeyPress="onKeyPress" :input="inputs[inputName]" :inputName="inputName" />
      </div>
    </div>

    <LoginModalVue v-if="showModal" @close="loginUser" @closeModal="onCloseModal" />
    <div v-if="this.$store.state.auth.logged">
      <UserPanel :showUser="showUser" :showUserFunction="showUserFunction" :showProfileFunction="showProfileFunction" :showVectorCoastersFunction="showVectorCoastersFunction" />
      <ProfilePanel :showProfile="showProfile" :showProfileFunction="showProfileFunction" :openKeyboard="openKeyboard" @onInputChange="onInputChange" @onInputFocus="onInputFocus" :inputs="inputs" :saveDisable="saveDisable" />
      <VectorCoasters
        :showVectorCoasters="showVectorCoasters"
        :showVectorCoastersFunction="showVectorCoastersFunction"
        :sharedVectorScreen="sharedVectorScreen"
        :showSharedVectorScreen="showSharedVectorScreen"
        :vectorCoasterItems="vectorCoasterItems"
        :vectorCoasterNew="vectorCoasterNew"
        :toggleNewVectorMenuFunction="toggleNewVectorMenuFunction"
        :openKeyboard="openKeyboard"
        @onInputChange="onInputChange"
        @onInputFocus="onInputFocus"
        :inputs="inputs"
      />
      <VectorShared :sharedVectorScreen="sharedVectorScreen" :showSharedVectorScreen="showSharedVectorScreen" />
    </div>
  </div>
</template>

<script>
import KaratayLogoVue from '@/components/Map/Header/KaratayLogo.vue'
import HeaderFilters from '@/components/Map/Header/HeaderFilters.vue'
import SearchSvg from '@/assets/icons/SearchSvg.vue'
import FilterSvg from '@/assets/icons/FilterSvg.vue'
import LocationSvg from '@/assets/icons/LocationSvg.vue'
import LoginMenuSvg from '@/assets/icons/LoginMenuSvg.vue'
import VirtualKeyboardSvg from '@/assets/icons/VirtualKeyboardSvg.vue'
import LoginSvg from '@/assets/icons/UserSvg.vue'
import SettingsSvg from '@/assets/icons/SettingsSvg.vue'
import LoginModalVue from '@/components/Map/LoginUser/LoginModal.vue'
import UserPanel from '@/components/Map/LoginUser/UserPanel/UserPanel.vue'
import ProfilePanel from '@/components/Map/LoginUser/ProfilePanel/ProfilePanel.vue'
import VectorCoasters from '@/components/Map/VectorCoasters/VectorCoasters.vue'
import VectorShared from '@/components/Map/VectorCoasters/VectorShared.vue'
import SimpleKeyboard from '../LoginUser/SimpleKeyboard.vue'

export default {
  // inject: ['setMenu'],
  props: {
    showProject: Boolean,
    showVehicle: Boolean,
    showUser: Boolean,

    showUserFunction: Function,

    showProfile: Boolean,
    showProfileFunction: Function,

    showVectorCoasters: Boolean,
    showVectorCoastersFunction: Function,

    sharedVectorScreen: Boolean,
    showSharedVectorScreen: Function,

    showFilterFunction: Function,
    showFilter: Boolean,

    selectFilter: Function,
    selectedFilter: String,

    vectorCoasterItems: Boolean,
    vectorCoasterNew: Boolean,
    toggleNewVectorMenuFunction: Function,
  },
  components: {
    KaratayLogoVue,
    HeaderFilters,
    SearchSvg,
    FilterSvg,
    LocationSvg,
    LoginSvg,
    SettingsSvg,
    LoginModalVue,
    UserPanel,
    LoginMenuSvg,
    ProfilePanel,
    VectorCoasters,
    VectorShared,
    SimpleKeyboard,
    VirtualKeyboardSvg,
  },
  data() {
    return {
      showModal: false,
      user: false,
      saveDisable: true,
      inputs: {
        searchInput: '',
        newPhoneNumber: '',
        altlik_adi: '',
      },
      inputName: '',
      keyboardVisible: false,
      searchInput: '',
    }
  },
  computed: {
    // a computed getter
    placeholderChange() {
      // `this` points to the component instance
      switch (this.selectedFilter) {
        case 'Ada':
          return 'Ada parsel giriniz...'
        case 'Araç':
          return 'Araçlarda ara...'
        case 'Kamera':
          return 'Kameralarda ara...'
        case 'Ruhsat':
          return 'Ruhsatlarda ara...'
        case 'Yatırımlar':
          return 'Yatırımlarda ara...'
        case 'Envanter':
          return 'Envanterde ara...'
        case 'Talepler':
          return 'Taleplerde ara...'
        default:
          return 'Koordinasyon ekranında ara...'
      }
    },
  },
  methods: {
    closeMap() {
      this.setMenu(0)
      this.$store.dispatch('setKaratayMahalleler', {
        url: '',
        type: '',
      })
      this.$store.dispatch('setKaratayAdaParsel', {
        url: '',
        type: '',
      })
      this.$store.dispatch('setKaratayRuhsatli', {
        url: '',
        type: '',
      })
      this.$store.dispatch('setKaratayRuhsatsiz', {
        url: '',
        type: '',
      })
      this.$store.dispatch('setBuyukSehirImar1000', {
        url: '',
        type: '',
      })
      this.$store.dispatch('setBuyukSehirCDP', {
        url: '',
        type: '',
      })
      this.$store.dispatch('setBuyukSehirNIP', {
        url: '',
        type: '',
      })
    },
    loginUser() {
      if (this.$store.state.auth.logged) {
        if (!this.showUser) {
          this.showUserFunction(true)
        } else {
          this.showUserFunction(false)
        }
      } else {
        this.showModal = !this.showModal
      }
    },
    onCloseModal() {
      this.showModal = false
    },
    btnKonumGetir() {
      this.$emit('btnGetLocationClick')
    },
    openKeyboard(string = '') {
      if (string?.length > 0) {
        this.keyboardVisible = false
        this.inputs.altlik_adi = ''
        this.inputs.newPhoneNumber = ''
      } else {
        this.keyboardVisible = !this.keyboardVisible
      }
    },
    onChange(input) {
      this.inputs[this.inputName] = input
    },
    onKeyPress(button) {},
    onInputChange(input) {
      this.inputs[input.target.id] = input.target.value
    },
    onInputFocus(input) {
      this.inputName = input.target.id
    },
  },
}
</script>

<style scoped>
.headerMap {
  position: absolute;
  top: 0;
  width: 80%;
}

.headerMapWidth {
  position: absolute;
  top: 0;
}

.form-control::-webkit-input-placeholder {
  color: #c8c8c8;
}

.loginMenuSvg {
  width: 1.2rem;
  margin-bottom: 0.2rem;
}

.input-group-text {
  background-color: #fff !important;
  padding: 0.65rem;
  color: #c8c8c8;
}

.input-group {
  width: 100%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.form-control:focus {
  box-shadow: none;
}

.userPanel button {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  font-size: 15px;
  padding: 0.65rem 1rem;
}

.locatinButton {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 2.8rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.bi {
  transform: scale(1.4) !important;
}

.virtualKeyboard {
  display: block;
  width: 100%;
  padding: 0.8rem 0;
  border: none;
  background-color: #ececec;
  color: #000;
  border-radius: 8px;
  text-align: center;
  user-select: none;
}

.simple-keyboard {
  max-width: 2850px;
  min-width: 950px;
  margin-top: 10rem;
  margin-left: -30rem;
  z-index: 100;
}

.simple-keyboard:focus {
  box-shadow: none;
  border-color: #dee2e6;
  border-right: 0;
}

.karatay-logo {
  position: absolute;
  top: 0px;
  left: 50px;
}

.search-panel {
  position: absolute;
  top: 50px;
  left: 200px;
  right: 20px;
  width: 35vw;
}

.profile-panel {
  position: absolute;
  top: 50px;
  right: 200px;
  right: 20px;
}

/* START MSY CSS */
/* === Responsive Ayarlar === */
/* Küçük Ekranlar (576px ve altı) */
@media (max-width: 576px) {
}

/* Tabletler (768px) */
@media (min-width: 768px) {
}

/* Laptoplar (1024px) */
@media (min-width: 1024px) {
}

/* Geniş Ekranlar (1440px) */
@media (min-width: 1440px) {
}

/* 4K ve Daha Büyük (3840px) */
@media (min-width: 3840px) {
  .search-panel {
    top: 85px;
    left: 320px;
  }

  .profile-panel {
    position: absolute;
    top: 85px;
    right: 50px;
  }
}
/* END MSY CSS */

@media screen and (min-width: 3800px) {
  .userPanel button {
    font-size: 30px;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
  svg {
    width: 3rem;
    height: 2rem;
  }
  .loginMenuSvg {
    width: 2.5rem;
  }
  .headerRight {
    margin-right: 1rem;
  }
  .headerRight2 {
    margin-right: 1rem;
  }
  .input-group-text {
    padding: 1.65rem;
  }
  .form-control::-webkit-input-placeholder {
    font-size: 30px;
  }
  .form-control {
    font-size: 30px;
  }
  .locatinButton {
    min-width: 5rem;
    padding: 0 1rem;
    margin-left: 1rem !important;
  }
  .bi {
    transform: scale(2.4) !important;
  }
  .simple-keyboard {
    max-width: 850px;
    margin-top: 15rem;
    margin-right: auto;
    z-index: 100;
  }
}

@media only screen and (min-width: 1700px) {
  .headerRight {
    margin-left: 0.5rem;
  }
  .headerRight2 {
    margin-left: 1.5rem;
  }
}

@media only screen and (max-width: 1700px) {
  .headerRight {
    margin-left: 1rem;
  }
  .headerRight2 {
    margin-left: 1rem;
  }
}

@media only screen and (max-width: 1500px) {
  .headerMap {
    width: 73.35%;
  }
  .headerRight {
    margin-left: 2.5rem;
  }
  .headerRight2 {
    margin-left: 2.5rem;
  }

  .input-group {
    width: 80%;
  }
  .simple-keyboard {
    max-width: 2850px;
    min-width: 750px;
    margin-top: 10rem;
    margin-left: -30rem;
  }
}

@media only screen and (max-width: 1050px) {
  .headerMap {
    width: 68.35%;
  }

  .headerRight {
    margin-left: 4rem;
    margin-top: 2.5rem !important;
  }
  .headerRight2 {
    margin-left: 4rem;
    margin-top: 2.5rem !important;
  }

  .input-group {
    width: 100%;
  }

  .input-group button {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    padding-right: 0.5rem;
  }

  .form-control {
    font-size: 11px;
  }

  .locatinButton {
    padding: 0.2rem 0.65rem;
  }
  .userPanel button {
    font-size: 11px;
    padding: 0.5rem;
  }
  .simple-keyboard {
    max-width: 2850px;
    min-width: 575px;
    margin-top: 10rem;
    margin-left: -30rem;
  }
}

@media only screen and (max-width: 910px) {
  .headerMap {
    width: 68.35%;
  }
  .headerRight {
    margin-left: auto;
  }
  .headerRight2 {
    margin-left: auto;
  }
  svg {
    width: 0.88rem;
  }
  .loginMenuSvg {
    width: 0.88rem;
  }

  .userPanel button {
    font-size: 10px;
  }
  .simple-keyboard {
    max-width: 2850px;
    min-width: 475px;
    margin-top: 10rem;
    margin-left: -22rem;
  }
}
</style>
