<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    viewBox="0 0 34.055 34.086"
  >
    <g
      id="Group_105"
      data-name="Group 105"
      transform="translate(-3.422 -3.409)"
    >
      <path
        id="Path_206"
        data-name="Path 206"
        d="M6,12.226A10.226,10.226,0,1,1,16.226,22.452,10.226,10.226,0,0,1,6,12.226Z"
        transform="translate(4.226 1.409)"
        fill="#a4a4a4"
        fill-rule="evenodd"
      />
      <path
        id="Path_207"
        data-name="Path 207"
        d="M7.842,17.538C10.611,16.377,14.3,16,19.037,16s8.411.376,11.178,1.531A9.664,9.664,0,0,1,35.98,23.94a1.755,1.755,0,0,1-1.673,2.286H3.757a1.748,1.748,0,0,1-1.666-2.275A9.662,9.662,0,0,1,7.842,17.538Z"
        transform="translate(1.414 11.269)"
        fill="#a4a4a4"
        fill-rule="evenodd"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
  },
};
</script>