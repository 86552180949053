<template>
  <ol-map
    :loadTilesWhileAnimating="true"
    :loadTilesWhileInteracting="true"
    style="height: 100%"
    :style="{
      width: showProject || showVehicle || showUser || showProfile || showVectorCoasters || vectorCoasterNew ? '80%' : '100%',
      transition: 'width 0.3s',
    }"
    :moveTolerance="10"
    id="map"
  >
    <ol-view ref="view" :center="center" :rotation="rotation" :zoom="zoom" :projection="projection" :enableRotation="false" @zoomChanged="zoomChanged" />

    <ol-tile-layer>
      <ol-source-xyz :url="selectedScreen" />
    </ol-tile-layer>

    <ClustersContainer v-if="this.$store.state.auth.logged && showCluster" />

    <DrawVectorLayer
      :drawEnable="drawEnable"
      :drawType="drawType"
      :drawend="drawend"
      :drawstart="drawstart"
      :modifyEnabled="modifyEnabled"
      :zones="zones"
      :showProject="showProject"
      :selectedFeatures="selectedFeatures"
      :selectCondition="selectCondition"
      :featureSelected="featureSelected"
      :mahallelerVisible="mahallelerVisible"
    />

    <ol-context-menu-control :items="contextMenuItems" />

    <LayersIndex :mahalleler="mahalleler" :selectedFeatures="selectedFeatures" :selectCondition="selectCondition" :featureSelected="featureSelected" :zones="zones" />
    <ol-vector-layer>
      <ol-source-vector ref="markers"></ol-source-vector>
      <ol-style>
        <ol-style-icon :src="markerIcon" :scale="0.3"></ol-style-icon>
      </ol-style>
    </ol-vector-layer>

    <ol-interaction-clusterselect v-if="this.$store.state.auth.logged" @select="featureSelected" :pointRadius="20">
      <ol-style>
        <ol-style-stroke color="green" :width="1"></ol-style-stroke>
        <ol-style-fill color="rgba(255,255,255,0.5)"></ol-style-fill>
        <ol-style-icon :src="markerIcon" :scale="0.3"></ol-style-icon>
      </ol-style>
    </ol-interaction-clusterselect>

    <VehicleContainer />
  </ol-map>
  <VehicleDetail v-if="selectedVehicle" :showVehicle="showVehicle" :showVehicleFunction="showVehicleFunction" :selectedVehicle="selectedVehicle" :setSelectedVehicle="setSelectedVehicle" />
  <ClusterDetail v-if="selectedProject" :selectedProject="selectedProject" :images="images" :showProject="showProject" :showProjectFunction="showProjectFunction" :setSelectedroject="setSelectedroject" />
</template>

<script>
import { ref } from 'vue'
import ClustersContainer from './Clusters/ClustersContainer.vue'
import ClusterDetail from './Clusters/ClusterDetail.vue'
import DrawVectorLayer from './Draws/DrawVectorLayer.vue'
import LayersIndex from './Layers/LayersIndex.vue'
import markerIcon from '@/assets/png/marker.png'
import Feature from 'ol/Feature.js'
import Point from 'ol/geom/Point.js'
import VehicleContainer from './Vehicles/VehicleContainer.vue'
import VehicleDetail from './Vehicles/VehicleDetail.vue'

export default {
  props: {
    showCluster: Boolean,
    showProject: Boolean,
    showVehicle: Boolean,
    showUser: Boolean,
    vectorCoasterNew: Boolean,
    showProfile: Boolean,
    mahallelerVisible: Boolean,
    showVectorCoasters: Boolean,
    showProjectFunction: Function,
    showVehicleFunction: Function,
    getMapView: Function,
    drawstart: Function,
    drawend: Function,
    drawEnable: Boolean,
    drawType: String,
    modifyEnabled: Boolean,
    selectedFeatures: Object,
    selectedScreen: String,
    zones: Array,
    mahalleler: Array,
    selectCondition: Function,
    setSelectedGeom: Function,
  },
  inheritAttrs: false,
  data() {
    return {
      center: ref([32.6240873, 37.8756575]),
      zoom: ref(13),
      rotation: ref(0),
      projection: ref('EPSG:4326'),
      selectedProject: {},
      selectedVehicle: {},
      images: ref([]),
      markerIcon: markerIcon,
      contextMenuItems: ref([]),
    }
  },
  components: {
    ClustersContainer,
    ClusterDetail,
    DrawVectorLayer,
    LayersIndex,
    VehicleContainer,
    VehicleDetail,
  },
  created() {
    this.$store.dispatch('setArventoData')
  },
  mounted() {
    this.getMapView(this.$refs.view, this.$refs.markers)
    this.contextMenuItems = [
      {
        text: 'Harita Menüsü',
        classname: 'zoom-in',
      },
      {
        text: 'Çizim İptal Et',
        classname: 'add-a-marker',
        icon: this.markerIcon,
        callback: (val) => {
          this.$emit('update:modifyEnabled')
        },
      },
      {
        text: 'İşaret Ekle',
        classname: 'add-a-marker',
        icon: this.markerIcon,
        callback: (val) => {
          const feature = new Feature({
            geometry: new Point(val.coordinate),
          })
          this.$refs.markers.source.addFeature(feature)
        },
      },
    ]
  },
  methods: {
    test(e) {
      e.preventDefault()
      e.stopPropagation()
    },
    zoomChanged() {
      if (this.showProject) {
        this.showProjectFunction(false)
      }
      if (this.showVehicle) {
        this.showVehicleFunction(false)
      }
    },
    async setSelectedroject(proje) {
      this.selectedProject = proje
      this.images = []
      const galery = await this.selectedProject?.galeri?.reduce((acc, curr) => {
        acc?.push({
          largeURL: `https://birbaskakaratay.com/koordinasyon-ekrani/assets/img/projeler/${curr?.klasor_yolu}/${curr?.resim_adi}`,
          thumbnailURL: `https://birbaskakaratay.com/koordinasyon-ekrani/assets/img/projeler/${curr?.klasor_yolu}/${curr?.resim_adi}`,
          width: 1500,
          height: 1000,
        })
        return acc
      }, [])
      this.images = await galery
    },
    setSelectedVehicle(vehicle) {
      this.selectedVehicle = vehicle
    },
    featureSelected(event) {
      this.setSelectedGeom(event)
      const coordinate = event.selected[0]?.values_?.geometry?.flatCoordinates
      const selectedProject = this.$store.state.investment.data?.reduce((acc, curr) => {
        if (coordinate?.includes(Number(curr?.Latitude))) {
          acc = curr
        }
        return acc
      }, {})
      const selectedVehicle = this.$store.state.arvento.data?.reduce((acc, curr) => {
        if (coordinate?.includes(Number(curr?.LongitudeX)) && coordinate?.includes(Number(curr?.LatitudeY))) {
          acc = curr
        }
        return acc
      }, {})

      this.setSelectedVehicle(selectedVehicle)

      if (Object.keys(selectedProject).length === 0) {
        this.setSelectedroject(null)
      } else {
        this.setSelectedroject(selectedProject)
      }
      if (selectedProject !== null && coordinate?.includes(Number(selectedProject?.Latitude))) {
        this.showProjectFunction(true)
      } else {
        this.showProjectFunction(false)
      }

      if (Object.keys(selectedVehicle).length === 0) {
        this.setSelectedVehicle(null)
        this.showVehicleFunction(false)
      } else {
        this.setSelectedVehicle(selectedVehicle)
        this.showVehicleFunction(true)
      }
    },
  },
}
</script>

<style>
.overlay-content {
  background: #efefef;
  box-shadow: 0 5px 10px rgb(2 2 2 / 20%);
  padding: 10px 20px;
  font-size: 16px;
  color: black;
}
</style>
