<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 45 44"
  >
    <g id="circle-vector-svgrepo-com" transform="translate(-0.12 -0.75)">
      <path
        id="Path_57"
        data-name="Path 57"
        d="M51.81,33.415a18.4,18.4,0,1,1-18.4,18.4,18.4,18.4,0,0,1,18.4-18.4m0-1.415a19.81,19.81,0,1,0,19.81,19.81A19.81,19.81,0,0,0,51.81,32Z"
        transform="translate(-29.213 -29.335)"
        :fill="fill"
      />
      <g id="Group_15" data-name="Group 15" transform="translate(-0.311 -0.25)">
        <rect
          id="Rectangle_9"
          data-name="Rectangle 9"
          width="7"
          height="6"
          transform="translate(19.431 1)"
          :fill="fill"
        />
        <rect
          id="Rectangle_14"
          data-name="Rectangle 14"
          width="7"
          height="6"
          transform="translate(19.431 39)"
          :fill="fill"
        />
        <rect
          id="Rectangle_15"
          data-name="Rectangle 15"
          width="7"
          height="6"
          transform="translate(38.431 20)"
          :fill="fill"
        />
        <rect
          id="Rectangle_16"
          data-name="Rectangle 16"
          width="7"
          height="6"
          transform="translate(0.431 20)"
          :fill="fill"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
    height: String,
    fill: String,
  },
};
</script>