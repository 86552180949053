<template>
  <svg
    id="location-pin-svgrepo-com_3_"
    data-name="location-pin-svgrepo-com (3)"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 32.121 42.828"
  >
    <path
      id="Path_282"
      data-name="Path 282"
      d="M24.061,0A16.06,16.06,0,0,0,8,16.061C8,19.86,9.674,23.2,11.527,26.1L22.968,42.264a1.338,1.338,0,0,0,2.184,0L36.594,26.1a17.987,17.987,0,0,0,3.527-10.038A16.06,16.06,0,0,0,24.061,0Zm0,25.429a9.369,9.369,0,1,1,9.369-9.369A9.369,9.369,0,0,1,24.061,25.429Z"
      transform="translate(-8)"
      fill="#3c4043"
    />
    <path
      id="Path_283"
      data-name="Path 283"
      d="M28.03,12a8.03,8.03,0,1,0,8.03,8.03A8.031,8.031,0,0,0,28.03,12Zm0,14.722a6.692,6.692,0,1,1,6.692-6.692A6.692,6.692,0,0,1,28.03,26.722Z"
      transform="translate(-11.97 -3.97)"
      fill="#3c4043"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
    height: String,
  },
};
</script>