<template>
  <div class="d-flex flex-column position-relative">
    <Transition name="slide-fade">
      <div
        class="p-1 bg-white ms-3 d-flex flex-column mt-2 uyduButton"
        v-if="hideDiv"
      >
        <button
          @click="
            mapScreen === 'Sokak'
              ? handleChangeScreen('Uydu')
              : handleChangeScreen('Sokak')
          "
          type="button"
          class="btn btn-light"
        >
          <span></span>
          <img
            src="../../../assets/png/uydu.jpeg"
            alt=""
            v-if="mapScreen !== 'Sokak'"
          />
          <img src="../../../assets/png/arazi.jpeg" alt="" v-else />
          <div class="uyduButtonName" v-if="mapScreen !== 'Sokak'">Sokak</div>
          <div class="uyduButtonName" v-else>Uydu</div>
        </button>
      </div>
    </Transition>
    <div class="p-1 bg-white ms-3 d-flex flex-column mt-2 uyduButton">
      <button
        @click="handleChangeScreen(mapScreen)"
        type="button"
        class="btn btn-light"
      >
        <span></span>
        <img
          src="../../../assets/png/uydu.jpeg"
          alt=""
          v-if="mapScreen === 'Sokak'"
        />
        <img src="../../../assets/png/arazi.jpeg" alt="" v-else />
        <div class="uyduButtonName" v-if="mapScreen === 'Sokak'">Sokak</div>
        <div class="uyduButtonName" v-else>Uydu</div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hideDiv: false,
      mapScreen: "Sokak",
    };
  },
  props: {
    click: Function,
    changeSelectedScreen: Function,
  },
  methods: {
    handleChangeScreen(screen) {
      const hide = !this.hideDiv;
      this.hideDiv = hide;
      this.mapScreen = screen;
      if (screen === "Sokak") {
        this.changeSelectedScreen(
          "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        );
      } else {
        this.changeSelectedScreen(
          "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
        );
      }
    },
  },
};
</script>

<style scoped>
.uyduButton img {
  /* position: relative; */
  border-radius: 5px;
  z-index: 1;
  height: 5.38rem;
}

.uyduButton span {
  position: absolute;
  opacity: 0.5;
  border-radius: 5px;
  background-image: linear-gradient(#fff, #000);
  z-index: 2;
  width: 5.38rem;
  height: 5.38rem;
}

.uyduButton {
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  align-self: center;
}

.uyduButtonName {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 14px;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 300;
  z-index: 999;
}

.uyduButtonName2 {
  position: absolute;
  right: 0;
  left: 0;
  margin-bottom: 6rem;
  bottom: 14px;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 300;
  z-index: 999;
}

button {
  padding: 0;
}

.slide-fade-enter-active {
  transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.5s;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(80px);
  opacity: 0;
}

@media only screen and (min-width: 3800px) {
  .uyduButton img {
    height: 7.5rem;
  }
  .uyduButton span {
    width: 7.5rem;
    height: 7.5rem;
  }
  .uyduButtonName {
    bottom: 20px;
    font-size: 1.3rem;
    font-weight: 500;
  }
  .uyduButtonName2 {
    margin-bottom: 8rem;
    bottom: 20px;
    font-size: 1.3rem;
    font-weight: 500;
  }
}

@media only screen and (max-width: 1500px) {
  .uyduButton img {
    height: 5rem;
  }
  .uyduButton span {
    width: 5rem;
    height: 5rem;
  }
  .uyduButtonName {
    bottom: 13px;
    font-size: 1rem;
  }
}
@media only screen and (max-width: 1100px) {
  .uyduButton img {
    height: 4rem;
  }
  .uyduButton span {
    width: 4rem;
    height: 4rem;
  }
  .uyduButtonName {
    bottom: 9px;
    font-size: 0.8rem;
    font-weight: 500;
  }
}

@media only screen and (max-width: 770px) {
  .uyduButton img {
    height: 3.3rem;
  }
  .uyduButton span {
    width: 3.3rem;
    height: 3.3rem;
  }
  .uyduButtonName {
    bottom: 9px;
    font-size: 0.65rem;
    font-weight: 500;
  }
}


</style>