<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 40.059 28.039"
  >
    <path
      id="Path_73"
      data-name="Path 73"
      d="M10,14h8.013"
      transform="translate(6.023 4.026)"
      fill="none"
      stroke="#515151"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="4"
    />
    <path
      id="Path_74"
      data-name="Path 74"
      d="M17.5,10v.02"
      transform="translate(13.548 0.013)"
      fill="none"
      stroke="#515151"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="4"
    />
    <path
      id="Path_75"
      data-name="Path 75"
      d="M14,9.99v.02"
      transform="translate(10.036 0.003)"
      fill="none"
      stroke="#515151"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="4"
    />
    <path
      id="Path_76"
      data-name="Path 76"
      d="M17.5,13.99v.02"
      transform="translate(13.548 4.016)"
      fill="none"
      stroke="#515151"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="4"
    />
    <path
      id="Path_77"
      data-name="Path 77"
      d="M6.5,14v.02"
      transform="translate(2.512 4.026)"
      fill="none"
      stroke="#515151"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="4"
    />
    <path
      id="Path_78"
      data-name="Path 78"
      d="M10,10v.02"
      transform="translate(6.023 0.013)"
      fill="none"
      stroke="#515151"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="4"
    />
    <path
      id="Path_79"
      data-name="Path 79"
      d="M6.5,10v.02"
      transform="translate(2.512 0.013)"
      fill="none"
      stroke="#515151"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="4"
    />
    <path
      id="Path_80"
      data-name="Path 80"
      d="M31.046,6c3.777,0,5.666,0,6.84,1.174s1.174,3.062,1.174,6.84v8.013c0,3.777,0,5.666-1.174,6.84s-3.062,1.174-6.84,1.174H11.013c-3.777,0-5.666,0-6.84-1.174S3,25.8,3,22.026V14.013c0-3.777,0-5.666,1.174-6.84S7.236,6,11.013,6H31.046Z"
      transform="translate(-1 -4)"
      fill="none"
      stroke="#515151"
      stroke-linejoin="round"
      stroke-width="4"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
    height: String,
  },
};
</script>