<template>
  <svg
    id="lock-svgrepo-com_1_"
    data-name="lock-svgrepo-com (1)"
    xmlns="http://www.w3.org/2000/svg"
    :height="height"
    viewBox="0 0 34.144 42.68"
  >
    <path
      id="Path_67"
      data-name="Path 67"
      d="M15.268,15.134a2.134,2.134,0,1,0-4.268,0V19.4a2.134,2.134,0,0,0,4.268,0Z"
      transform="translate(3.938 10.474)"
      fill="#a4a4a4"
    />
    <path
      id="Path_68"
      data-name="Path 68"
      d="M10.4,15.061A8.129,8.129,0,0,0,4,22.848V34.667C4,40.354,8.941,44.68,14.67,44.68h12.8c5.729,0,10.67-4.326,10.67-10.013V22.848a8.129,8.129,0,0,0-6.4-7.787V12.67a10.67,10.67,0,0,0-21.34,0ZM27.474,12.67V14.8H14.67V12.67a6.4,6.4,0,1,1,12.8,0ZM8.268,22.848a4.043,4.043,0,0,1,4.268-3.775H29.608a4.044,4.044,0,0,1,4.268,3.775V34.667c0,3.016-2.7,5.745-6.4,5.745H14.67c-3.7,0-6.4-2.73-6.4-5.745Z"
      transform="translate(-4 -2)"
      fill="#a4a4a4"
      fill-rule="evenodd"
    />
  </svg>
</template>


<script>
export default {
  props: {
    height: String,
  },
};
</script>