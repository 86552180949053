const getModule = {
  state: {
    loading: false,
  },
  mutations: {
    setLoading(state, status) {
      state.loading = status
    },
  },
  actions: {
    showLoading({ commit }) {
      commit('setLoading', true) // Loading'i başlat
    },
    hideLoading({ commit }) {
      commit('setLoading', false) // Loading'i bitir
    },
  },
}

export default getModule
