<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33.907"
    height="33.907"
    viewBox="0 0 33.907 33.907"
    class="me-2"
  >
    <g id="Complete" transform="translate(2 2)">
      <g id="add-square">
        <g id="Group_70" data-name="Group 70">
          <path
            id="_--Rectangle"
            data-name="--Rectangle"
            d="M1.459,0H28.448a1.459,1.459,0,0,1,1.459,1.459V28.448a1.459,1.459,0,0,1-1.459,1.459H1.459A1.459,1.459,0,0,1,0,28.448V1.459A1.459,1.459,0,0,1,1.459,0Z"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-width="4"
          />
          <line
            id="Line_1"
            data-name="Line 1"
            x1="10.306"
            transform="translate(9.57 15.055)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="4"
          />
          <line
            id="Line_2"
            data-name="Line 2"
            y1="10.306"
            transform="translate(14.723 9.902)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="4"
          />
        </g>
      </g>
    </g>
  </svg>
</template>