<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 41.326 48.597"
  >
    <path
      id="hand-svgrepo-com"
      d="M27.208,5.345v2.23a3.345,3.345,0,1,1,6.69,0v6.69a3.345,3.345,0,1,1,6.69,0V33.218A13.379,13.379,0,0,1,27.208,46.6H24.694a13.379,13.379,0,0,1-9.151-3.619L4.487,32.613a3.878,3.878,0,1,1,5.395-5.572l3.947,3.947V12.034a3.345,3.345,0,1,1,6.69,0V5.345a3.345,3.345,0,1,1,6.69,0Z"
      transform="translate(-1.26)"
      fill="none"
      stroke="#3c4043"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="4"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
    height: String,
  },
};
</script>