<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 38 38"
  >
    <g
      id="info-circle-svgrepo-com_3_"
      data-name="info-circle-svgrepo-com (3)"
      transform="translate(-0.5 -0.5)"
    >
      <circle
        id="Ellipse_178"
        data-name="Ellipse 178"
        cx="17.5"
        cy="17.5"
        r="17.5"
        transform="translate(2 2)"
        fill="none"
        :stroke="activeIndex !== 0 ? '#205c42' : '#fff'"
        stroke-width="3"
      />
      <path
        id="Path_284"
        data-name="Path 284"
        d="M12,20.661V11"
        transform="translate(7.5 7.643)"
        fill="none"
        :stroke="activeIndex !== 0 ? '#205c42' : '#fff'"
        stroke-linecap="round"
        stroke-width="3"
      />
      <circle
        id="Ellipse_179"
        data-name="Ellipse 179"
        cx="2"
        cy="2"
        r="2"
        transform="translate(17.5 10.167)"
        :stroke="activeIndex !== 0 ? '#205c42' : '#fff'"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
    height: String,
    activeIndex: Number,
  },
};
</script>