<template>
  <div class="logoCard">
    <div :class="showProject || showVehicle || showUser || showProfile || showVectorCoasters || vectorCoasterNew ? 'logo2' : 'logo'">
      <MapLogoSvg height="90px" />
    </div>
  </div>
</template>

<script>
import MapLogoSvg from '../../../assets/icons/MapLogoSvg.vue'

export default {
  props: {
    showProject: Boolean,
    showVehicle: Boolean,
    showUser: Boolean,
    vectorCoasterNew: Boolean,
    showProfile: Boolean,
    showVectorCoasters: Boolean,
  },
  components: {
    MapLogoSvg,
  },
}
</script>

<style scoped>
.logoCard {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  padding: 2.45rem 1.8rem;
  background-color: #205c42;
}

.logo2 {
  padding: 2.45rem 1.8rem;
  background-color: #205c42;
}

@media only screen and (min-width: 3800px) {
  .logo {
    padding: 4rem 3rem;
  }
  svg {
    height: 160px;
  }
  .logo2 {
    padding: 4rem 3rem;
  }
}


@media only screen and (max-width: 1500px) {
  .logoCard svg {
    height: 55px;
  }
}

@media only screen and (max-width: 910px) {
  .logoCard {
    display: none;
  }
}
</style>
