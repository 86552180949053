import { createStore } from "vuex"
import authModule from "./modules/auth"
import layerModule from "./modules/layer"
import layersModule from "./modules/layers"
import sharedUsersModule from "./modules/shared_users"
import sharedLayersModule from "./modules/shared_layers"
import karatayMahallelerModule from "./modules/karatay_mahalleler"
import karatayAdaParselModule from "./modules/karatay_ada_parsel"
import karatayRuhsatliModule from "./modules/karatay_ruhsatli"
import karatayRuhsatsizModule from "./modules/karatay_ruhsatsiz"
import buyuksehirImar1000Module from "./modules/buyuksehir_imar_1000"
import buyuksehirCDPModule from "./modules/buyuksehir_cdp"
import buyuksehirNIPModule from "./modules/buyuksehir_nip"
import statusModule from "./modules/status"
import investmentModule from "./modules/investment"
import vehicleModule from "./modules/vehicles"
import sourcesModule from "./modules/sources"
import arventoModule from "./modules/arvento"
import camerasModule from "./modules/cameras"
import loadingModule from "./modules/loading"

export default createStore({
  modules: {
    auth: authModule,
    layers: layersModule,
    layer: layerModule,
    shared_users: sharedUsersModule,
    shared_layers: sharedLayersModule,
    karatay_mahalleler: karatayMahallelerModule,
    karatay_ada_parsel: karatayAdaParselModule,
    karatay_ruhsatli: karatayRuhsatliModule,
    karatay_ruhsatsiz: karatayRuhsatsizModule,
    buyuksehir_imar_1000: buyuksehirImar1000Module,
    buyuksehir_cdp: buyuksehirCDPModule,
    buyuksehir_nip: buyuksehirNIPModule,
    status: statusModule,
    investment: investmentModule,
    vehicle: vehicleModule,
    sources: sourcesModule,
    arvento: arventoModule,
    cameras: camerasModule,
    loading: loadingModule,
  },
})
