<template>
  <div class="position-relative">
    <div
      class="offcanvas offcanvas-end border-0"
      :class="sharedVectorScreen ? 'show' : ''"
      tabindex="-1"
      :style="{
        visibility: sharedVectorScreen ? 'visible' : 'hidden',
        width: sharedVectorScreen ? screenSize : '0%',
      }"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <div class="container">
        <div class="closeCanvas" @click="showSharedVectorScreen(false)">
          <ArrowLeftSvg width="0.5rem" />
        </div>
        <div class="title">Adsız Katman Paylaşım Ayarları</div>
        <div class="information">
          <span><ExclamationSvg width="2.5rem" /></span>
          <div class="description">Oluşturmuş olduğunuz vektör altlığı paylaşmak istediğiniz kişi veya kişileri seçerek altlığınızın seçtiğiniz kişiler tarafından da görüntülenmesini sağlayabilirsiniz.</div>
        </div>
        <div class="addInput mt-3">
          <label class="user-select-none">Paylaşılacak Kişi(ler)</label>
          <div class="input-group">
            <span class="input-group-text">
              <UserProfileNameSvg width="1.2rem" />
            </span>
            <v-select v-model="sharedUser" label="first_name" :filterable="false" :options="options" @search="onSearchUser" class="form-control userSelect" placeholder="Adı Soyadı">
              <!-- No options template -->
              <template #no-options> Lütfen arama yapın... </template>

              <!-- Option template -->
              <template #option="option">
                <div class="d-center">
                  <img :src="'/img/' + option?.profile_photo" width="15" alt="avatar" />
                  {{ option ? option?.name + ' ' + option?.surname : 'Unknown Repository' }}
                </div>
              </template>

              <!-- Selected option template -->
              <template #selected-option="option">
                <div class="selected d-center">
                  <img :src="'/img/' + option?.profile_photo" width="15" alt="avatar" />
                  {{ option ? option?.name + ' ' + option?.surname : 'Unknown Repository' }}
                </div>
              </template>
            </v-select>
          </div>
        </div>
        <div class="datePickerWrapper d-flex justify-content-between">
          <div class="datePickerDiv">
            <label class="form-check-label" for="flexSwitchCheckDefault">Paylaşım Sonlanma Tarihi</label>
            <div class="input-group">
              <span
                class="input-group-text"
                :style="{
                  backgroundColor: dateVisible ? '#ebebeb !important' : '#fff !important',
                }"
              >
                <CalenderSvg width="1.2rem" />
              </span>
              <input id="date" name="date" type="date" placeholder="15.08.2023" class="form-control" v-model="sharedTime" :disabled="dateVisible" />
            </div>
          </div>
          <div class="">
            <div class="form-check form-switch d-flex flex-column">
              <label class="form-check-label" for="flexSwitchCheckDefault">Süre Sınırı Yok</label>
              <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" :value="dateVisible" @change="changeDateVisible" />
            </div>
          </div>
        </div>
        <button
          class="block"
          :disabled="!sharedUser"
          @click="addShareUser"
          :style="{
            borderColor: !sharedUser ? '#a4a4a4' : ' #2aa83f',
            color: !sharedUser ? '#a4a4a4' : '#2aa83f',
          }"
        >
          <CheckSvg width="0.9rem" :fill="!sharedUser ? '#a4a4a4' : '#2aa83f'" />Kişi Ekle
        </button>
        <table class="table mt-5">
          <thead>
            <tr>
              <th scope="col">Paylaşılacak Kişi(ler)</th>
              <th scope="col" class="text-align-end">Sonlanma Tarihi</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="this.$store.state.shared_users?.shared_users?.length <= 0">
              <td colspan="2" style="text-align: center">Veri bulunamadı</td>
            </tr>
            <tr v-for="(item, index) in this.$store.state.shared_users?.shared_users" :key="index">
              <td>{{ item.user.name + ' ' + item.user.surname }}</td>
              <td class="text-align-end">{{ !item.is_end_date ? this.getDateFormat(item?.end_date) : 'Sınırsız' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <div class="footer-logo">
        <FooterLogoWhiteSvg height="2.25rem" />
      </div> -->
    </div>
  </div>
</template>

<script>
import ArrowLeftSvg from '@/assets/icons/ArrowLeftSvg.vue'
// import FooterLogoWhiteSvg from "@/assets/icons/FooterLogoWhiteSvg.vue";
import ExclamationSvg from '@/assets/icons/ExclamationSvg.vue'
import UserProfileNameSvg from '@/assets/icons/UserProfileNameSvg.vue'
import CalenderSvg from '@/assets/icons/CalenderSvg.vue'
import CheckSvg from '@/assets/icons/CheckSvg.vue'
import { vMaska } from 'maska'
import { dateFormat } from '@/helpers'
import axios from '@/utils/axiosUtils'

export default {
  data() {
    return {
      dateVisible: false,
      screenSize: null,
      sharedUser: '',
      options: [],
      sharedTime: '',
    }
  },
  mounted() {
    this.getScreen()
  },
  watch: {
    sharedUser(value) {
      console.log('value: ', value)
    },
  },

  methods: {
    addShareUser() {
      this.$store.dispatch('showLoading')
      axios
        .post(`/layer/shareUser`, {
          layerId: this.$store.state.layer.vector._id,
          userId: this.sharedUser._id,
          isExpired: this.dateVisible,
          expiredDate: this.sharedTime,
        })
        .then((res) => {
          if (res.data.success) {
            location.reload()
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.$store.dispatch('hideLoading')
        })
    },
    onSearchUser(search, loading) {
      if (!search.length) {
        this.options = [] // Arama boşsa seçenekleri temizleyin
        return
      }

      loading(true) // Yükleme durumunu başlat
      axios
        .get(`/user/search?searchTerm=${encodeURIComponent(search)}`)
        .then((res) => {
          this.options = res.data.data
        })
        .catch((error) => {
          this.options = [] // Hata durumunda seçenekleri temizleyin
        })
        .finally(() => {
          loading(false) // Yükleme durumunu kapat
        })
    },
    getScreen() {
      const getWidth = window.screen.width
      console.log('getWidth: ', getWidth)
      if (getWidth < 1500 && getWidth > 1100) {
        this.screenSize = '27%'
      } else if (getWidth < 1100) {
        this.screenSize = '32.5%'
      } else {
        this.screenSize = '20%'
      }
    },
    changeDateVisible() {
      this.dateVisible = !this.dateVisible
    },
    getDateFormat(date) {
      if (date) {
        return dateFormat(date)
      }
      return null
    },
  },
  props: {
    sharedVectorScreen: Boolean,
    showSharedVectorScreen: Function,
  },
  directives: { maska: vMaska },
  components: {
    ArrowLeftSvg,
    // FooterLogoWhiteSvg,
    ExclamationSvg,
    UserProfileNameSvg,
    CalenderSvg,
    CheckSvg,
  },
}
</script>

<style scoped>
.userSelect {
  --vs-border-color: transporter;
}

.offcanvas {
  padding: 2rem 0 0 0;
}

.closeCanvas {
  background-color: #f8f8f8;
  padding: 0.5rem 1rem;
  margin: 1rem 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  max-width: 2.5rem;
}

.title {
  font-size: 1.2rem;
  text-decoration: 1px #205c42 solid underline;
  text-underline-offset: 3px;
  margin: 0 0 0 1rem;
}

.footer-logo {
  position: absolute;
  bottom: 30px;
  align-self: center;
}

.information {
  display: flex;
  align-items: center;
  background-color: #205c42;
  color: #fff;
  border-radius: 8px;
  margin: 1rem 0.5rem 0rem 0.5rem;
  padding: 1rem;
}

.description {
  font-size: 0.75rem;
  line-height: 1.1rem;
  margin: 0 0 0 1rem;
}

.addInput {
  margin: 0 0.5rem 0 0.5rem;
}

.form-control {
  font-size: 0.8rem;
  color: #c8c8c8;
}

.form-control::placeholder {
  color: #a4a4a4;
}

.form-control:focus {
  box-shadow: none;
  border-color: #dee2e6;
}

.form-control:disabled {
  background-color: #ebebeb;
}

.input-group {
  margin: 0.5rem 0 0 0;
}

.input-group input {
  border-left-width: 0;
  padding: 0.8rem 0;
  border-radius: 8px;
}

.input-group-text {
  background-color: #fff;
  border-right-width: 0;
  border-radius: 5px;
}

.input-group-text svg {
  height: 16px;
}

.datePickerWrapper {
  margin: 1rem 0.5rem 0 0.5rem;
}

.form-check-input {
  width: 3rem !important;
  height: 1.5rem !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23c8c8c8'/%3e%3c/svg%3e") !important;
}

.form-check-input:checked {
  background-color: #205c42;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
}

.form-check-input:focus {
  box-shadow: none;
  border-color: #dee2e6;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23c8c8c8'/%3e%3c/svg%3e");
}

.form-switch {
  display: flex;
  align-items: flex-end;
  padding: 0 0 0.5rem 0;
}

.block {
  display: block;
  border: 1px solid;
  background-color: #fff;
  border-radius: 5px;
  padding: 0.8rem;
  width: 95%;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  margin: 2rem auto 0 auto;
}

.block svg {
  width: 1.1rem;
  margin-right: 1rem;
  padding-bottom: 0.3rem;
}

.text-align-end {
  text-align: end;
}

@media screen and (min-width: 3800px) {
  .closeCanvas {
    padding: 1.5rem 3rem 1.5rem 2rem;
  }
  .closeCanvas svg {
    width: 0.85rem;
  }

  .title {
    font-size: 2.1rem;
    text-decoration: 3px #205c42 solid underline;
    text-underline-offset: 5px;
    margin: 1.5rem 0 0 1rem;
  }

  .footer-logo {
    bottom: 30px;
  }
  .footer-logo svg {
    height: 4.8rem;
  }

  .information {
    margin: 1rem 0.5rem 0rem 0.5rem;
    padding: 1rem;
  }
  .information svg {
    width: 3.5rem;
  }
  .description {
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0 0 0 1rem;
  }

  label {
    font-size: 1.8rem;
  }

  .input-group svg {
    margin: 0 0.5rem 0 0;
    width: 2rem;
  }
  .input-group input {
    padding: 1.5rem 0;
  }
  .form-control {
    border-left: 0;
    font-size: 1.6rem;
    color: #c8c8c8;
  }

  .form-check-input {
    width: 6rem !important;
    height: 3rem !important;
  }

  .block {
    padding: 1.2rem 17.1rem 1.2rem 17.2rem;
    font-size: 30px;
    margin: 4rem 1rem 0 1rem;
  }
  .block svg {
    width: 1.8rem;
    margin-right: 1rem;
    padding-bottom: 0.3rem;
  }

  .table tr {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 1500px) {
  .offcanvas {
    font-size: 14px;
  }
}

@media screen and (max-width: 1100px) {
  .datePickerWrapper {
    flex-wrap: wrap;
    gap: 15px;
  }
  .form-switch {
    align-items: center;
  }
  .input-group input {
    padding: 0.3rem 0;
  }
  .input-group-text svg {
    width: 1rem;
  }
  .block {
    padding: 0.3rem;
    margin: 2rem auto -1rem auto;
  }
  .datePickerWrapper {
    margin: 1rem 0.5rem -1rem 0.5rem;
  }
}

@media screen and (max-width: 770px) {
  .closeCanvas {
    padding: 0.3rem 1rem;
    margin: 0.6rem 0.3rem;
    max-width: 2.5rem;
  }
  .closeCanvas svg {
    width: 0.4rem;
  }
  .title {
    font-size: 0.9rem;
  }
  .information {
    margin: 1rem 0.5rem 0rem 0.5rem;
    padding: 0.5rem;
  }
  .information svg {
    width: 1.5rem;
  }
  .description {
    font-size: 10px;
    line-height: 0.9rem;
    margin: 0 0 0 1rem;
  }
  .addInput {
    font-size: 12px;
  }
  .form-control {
    font-size: 11px;
  }
  .form-check-label {
    font-size: 12px;
  }
  .input-group-text svg {
    width: 0.7rem;
  }
  .form-check-input.checkbox {
    width: 2.9rem !important;
    height: 1.2rem !important;
  }
  .block {
    width: 100%;
    padding: 0.3rem 0.5rem;
    font-size: 12px;
    cursor: pointer;
    text-align: center;
    margin: 1.5rem 0 0 0 !important;
  }
  .block svg {
    width: 0.7rem;
    margin-right: 0.5rem;
    padding-bottom: 0.1rem;
  }
  .table {
    margin-top: 1rem !important;
  }
  .table tr {
    font-size: 12px;
  }
}
</style>
