<template>
  <div class="position-relative">
    <div
      class="offcanvas offcanvas-end border-0"
      :class="showUser ? 'show' : ''"
      tabindex="-1"
      :style="{
        visibility: showUser ? 'visible' : 'hidden',
        width: showUser ? screenSize : '0%',
      }"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <div class="container">
        <UserInfo :showUserFunction="showUserFunction" />
        <!-- <ProjectInfo /> -->
        <UserMenu
          :showProfileFunction="showProfileFunction"
          :showVectorCoastersFunction="showVectorCoastersFunction"
        />
      </div>
      <!-- <div class="footer-logo">
        <FooterLogoWhiteSvg height="2.25rem" />
      </div> -->
    </div>
  </div>
</template>

<script>
import UserInfo from './UserInfo.vue'
// import ProjectInfo from './ProjectInfo.vue'
import UserMenu from './UserMenu.vue'
// import FooterLogoWhiteSvg from "@/assets/icons/FooterLogoWhiteSvg.vue";

export default {
  props: {
    showUser: Boolean,
    showUserFunction: Function,

    showProfileFunction: Function,
    showVectorCoastersFunction: Function,
  },
  data() {
    return {
      screenSize: null,
    }
  },
  mounted() {
    this.getScreen()
  },
  methods: {
    getScreen() {
      const getWidth = window.screen.width
      console.log('getWidth: ', getWidth)
      if (getWidth < 1500 && getWidth > 1100) {
        this.screenSize = '27%'
      } else if (getWidth < 1100) {
        this.screenSize = '32.5%'
      } else {
        this.screenSize = '20%'
      }
    },
  },
  components: {
    UserInfo,
    // ProjectInfo,
    UserMenu,
    // FooterLogoWhiteSvg,
  },
}
</script>

<style scoped>
.offcanvas {
  padding: 2rem 0 0 0;
}

.footer-logo {
  position: absolute;
  bottom: 30px;
  align-self: center;
}

@media screen and (min-width: 3800px) {
  .footer-logo {
    bottom: 30px;
  }
  .footer-logo svg {
    height: 4.8rem;
  }
}

/* @media screen and (max-width: 1100px) {
  .offcanvas.show {
    width: 32.5%;
  }
  .title {
    font-size: 1rem;
    margin: 0 0 1rem 0rem;
  }
} */
</style>
