<template>
  <svg
    id="user-svgrepo-com_5_"
    data-name="user-svgrepo-com (5)"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    viewBox="0 0 52.453 52.453"
    class="mb-2"
  >
    <path
      id="Path_5"
      data-name="Path 5"
      d="M6,18.034A15.8,15.8,0,0,1,21.561,2,15.8,15.8,0,0,1,37.123,18.034,15.8,15.8,0,0,1,21.561,34.068,15.8,15.8,0,0,1,6,18.034Z"
      transform="translate(4.667 -2)"
      fill="#c82b2b"
      fill-rule="evenodd"
    />
    <path
      id="Path_6"
      data-name="Path 6"
      d="M10.993,18.412C15.258,16.591,20.948,16,28.237,16s12.955.589,17.216,2.4a15.06,15.06,0,0,1,8.879,10.049,2.744,2.744,0,0,1-2.577,3.584H4.7a2.732,2.732,0,0,1-2.566-3.566A15.058,15.058,0,0,1,10.993,18.412Z"
      transform="translate(-2.008 20.419)"
      fill="#c82b2b"
      fill-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  props: {
    width: String,
  },
};
</script>